const licenceManagementStore = {
    search: {result: [], searchTerm: '', searchIndustry: ''},
    savedLicences: {filteredResult: [], saved: false},
};

export default (state= licenceManagementStore, action) =>{
    const {type, payload} = action;
    switch (type){
        case 'LICENCE_SEARCH':
         return {...state, search: payload};
        case 'SAVE_LICENCES':
            return {...state, savedLicences: payload};
        case 'REMOVE_LICENCE':
            return {...state, savedLicences: payload};
        default:
            return state;
    }
};