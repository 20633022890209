import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const ABNSvg = ({className, color, theme}) => (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 15'>
        <g id='Talia' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Dashboard---Talia---ABN-updated---Done' transform='translate(-323.000000, -227.000000)' fill='#000000'>
                <g id='Business-Details' transform='translate(41.000000, 214.000000)'>
                    <g id='ABN' transform='translate(282.000000, 12.000000)'>
                        <path fill={theme.colors[color] || color} d='M2,1 L20,1 C21.1045695,1 22,1.8954305 22,3 L22,14 C22,15.1045695 21.1045695,16 20,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,3 C-1.3527075e-16,1.8954305 0.8954305,1 2,1 Z M2.484,11 L4.884,5.36 L6.02,5.36 L8.42,11 L7.132,11 L6.62,9.744 L4.252,9.744 L3.74,11 L2.484,11 Z M4.692,8.656 L6.18,8.656 L5.436,6.84 L4.692,8.656 Z M8.784,11 L8.784,5.4 L11.384,5.4 C12.024,5.4 12.528,5.576 12.848,5.896 C13.104,6.152 13.232,6.464 13.232,6.848 L13.232,6.864 C13.232,7.496 12.896,7.848 12.496,8.072 C13.144,8.32 13.544,8.696 13.544,9.448 L13.544,9.464 C13.544,10.488 12.712,11 11.448,11 L8.784,11 Z M12.008,7.072 L12.008,7.056 C12.008,6.688 11.72,6.48 11.2,6.48 L9.984,6.48 L9.984,7.664 L11.12,7.664 C11.664,7.664 12.008,7.488 12.008,7.072 Z M12.32,9.296 C12.32,8.92 12.04,8.688 11.408,8.688 L9.984,8.688 L9.984,9.92 L11.448,9.92 C11.992,9.92 12.32,9.728 12.32,9.312 L12.32,9.296 Z M14.06,11 L14.06,5.4 L15.196,5.4 L17.82,8.848 L17.82,5.4 L19.036,5.4 L19.036,11 L17.988,11 L15.276,7.44 L15.276,11 L14.06,11 Z'></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const ABN = styled(withTheme(ABNSvg))`
  ${color};
  ${layout};
  ${space};
`;

ABN.defaultProps = {
    color: '#c4d0d6',
    height: 26,
    width: 24,
    marginRight: '0.5rem'
};

export default ABN;