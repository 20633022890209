import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const OpaqueLockSvg = ({className, color, theme, width, height}) => (
    <svg className={className} width={width} height={height} viewBox='0 0 32 32' version='1.1'
         xmlns='http://www.w3.org/2000/svg'>
        <title>AD345225-2EA5-4FB9-9AE9-96FCD95E5564@1.00x</title>
        <g id='BC-Form---Iteration-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='2.0:-LG---Iteration-1---Success' transform='translate(-74.000000, -792.000000)'>
                <g id='lock-24px' transform='translate(74.000000, 792.000000)'>
                    <g id='Group'>
                        <polygon id='Path' points='0 0 32 0 32 32 0 32'></polygon>
                        <polygon id='Path' opacity='0.87' points='0 0 32 0 32 32 0 32'></polygon>
                    </g>
                    <path
                        d='M24,10.6666667 L22.6666667,10.6666667 L22.6666667,8 C22.6666667,4.32 19.68,1.33333333 16,1.33333333 C12.32,1.33333333 9.33333333,4.32 9.33333333,8 L9.33333333,10.6666667 L8,10.6666667 C6.53333333,10.6666667 5.33333333,11.8666667 5.33333333,13.3333333 L5.33333333,26.6666667 C5.33333333,28.1333333 6.53333333,29.3333333 8,29.3333333 L24,29.3333333 C25.4666667,29.3333333 26.6666667,28.1333333 26.6666667,26.6666667 L26.6666667,13.3333333 C26.6666667,11.8666667 25.4666667,10.6666667 24,10.6666667 Z M12,8 C12,5.78666667 13.7866667,4 16,4 C18.2133333,4 20,5.78666667 20,8 L20,10.6666667 L12,10.6666667 L12,8 Z M24,26.6666667 L8,26.6666667 L8,13.3333333 L24,13.3333333 L24,26.6666667 Z M16,22.6666667 C17.4666667,22.6666667 18.6666667,21.4666667 18.6666667,20 C18.6666667,18.5333333 17.4666667,17.3333333 16,17.3333333 C14.5333333,17.3333333 13.3333333,18.5333333 13.3333333,20 C13.3333333,21.4666667 14.5333333,22.6666667 16,22.6666667 Z'
                        id='Shape' fill={theme.colors[color] || color} fillRule='nonzero'></path>
                </g>
            </g>
        </g>
    </svg>
);

const OpaqueLock = styled(withTheme(OpaqueLockSvg))`
  ${color};
  ${layout};
  ${space};
`;

OpaqueLock.defaultProps = {
    color: '#E2173D',
    height: 32,
    width: 32,
    marginRight: '0.5rem'
};

export default OpaqueLock;