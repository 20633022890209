const user = {
    snswCustomerId: '',
    email: '',
    firstName: '',
    lastName: '',
    businesses: [],
    selectedBusinessId: '',
    selectedBusiness: undefined,
    initialised: false,
    updating: false,
};

export default user;
