import env from '@beam-australia/react-env';
import {getCookie} from './cookie';

const api = {
    getSecure: (url, returnRawResponse = false) => {
        return api.request(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
            },
        }, returnRawResponse);
    },
    downloadSecureFile: (url, fileName = 'submittedApplication.pdf') => {
        api.getSecure(url, true)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.onclick = () => console.info('Downloading secure file');
                link.click();
                link.remove();
            });
    },
    postSecure: (url, body={}, headers={}, returnRawResponse = false) => {
        return api.request(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json', ...headers }
        }, returnRawResponse);
    },
    postSecureFile: (url, body={}, headers={}) => {
        return api.request(url, {
            method: 'POST',
            credentials: 'include',
            body: body,
            headers: {...headers }
        });
    },
    putSecure: (url, body = {}) => {
        return api.request(url, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        });
    },
    patchSecure: (url, body = {}) => {
        return api.request(url, {
            method: 'PATCH',
            credentials: 'include',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        });
    },
    deleteSecure: url => api.request(url, {method: 'DELETE', credentials: 'include'}),
    request: async (url, options, returnRawResponse) => {
        const response = await fetch(resolvePath(url), resolveOptions(url, options));

        const {headers, status} = response;
        const contentType = headers.get('content-type');
        if (status < 200 || status > 299) {
            const error = Error('Received a non 2xx response');
            error.type = status;
            if (status === 503) {
                error.retryAfter = headers.get('retry-after');
            }
            if (contentType?.includes('json')) {
                error.details = await response.json();
            }
            throw error;
        }

        if (returnRawResponse) {
            return response;
        }
        if (contentType?.includes('json')) {
            return await response.json();
        }
        return await response.text();
    },
    getQueryParameters: (data) => {
        const queryString = Object.entries(data)
            .filter(([, val]) => !!val)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&');
        return queryString ? `?${queryString}` : '';
    }
};

const resolvePath = url => {
    if (url.startsWith('/api') || url.startsWith('https://') || url.startsWith('http://')) {
        return url;
    }
    if (url.startsWith('/user/licences')) {
        return `${env('API_LM_FLUX_CONTEXT_PATH')}${url}`;
    }
    return `${env('API_CONTEXT_PATH')}${url}`;
};

const resolveOptions = (url, options) => {
    const result = {...options};
    const {method} = result;
    if (method && method !== 'GET' && url.startsWith('/')) {
        const csrf = getCookie('CSRF');
        if (csrf) {
            result.headers = {'X-CSRF-Token': csrf, ...result.headers};
        }
    }
    const apiKey = env('APIGEE_API_KEY');
    if (apiKey && apiKey.length > 0) {
        result.headers = {'X-API-Key': apiKey, ...result.headers};
    }
    const mode = env('API_MODE');
    if (mode && mode.length > 0) {
        result.mode = mode;
    }
    return result;
};

export default api;
