import React from 'react';
import styled from 'styled-components/macro';
import {color, layout} from 'styled-system';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const TimingSvg = ({className, color, theme}) => (
    <svg className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <g id='Icons/timing' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Group' fill={theme.colors[color] || color} fillRule='nonzero'>
                <path id='Shape'
                      d='M11.988,0 C5.364,0 0,5.376 0,12 C0,18.624 5.364,24 11.988,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 11.988,0 Z M12,21.6 C6.696,21.6 2.4,17.304 2.4,12 C2.4,6.696 6.696,2.4 12,2.4 C17.304,2.4 21.6,6.696 21.6,12 C21.6,17.304 17.304,21.6 12,21.6 Z'></path>
                <polygon id='Path'
                         points='12.75 6 11 6 11 13.2131148 17.125 17 18 15.5213115 12.75 12.3114754'></polygon>
            </g>
        </g>
    </svg>
);

const Timing = styled(withTheme(TimingSvg))`
  ${color}
  ${layout}
`;

Timing.defaultProps = {
    color: 'blackBlue',
    height: 24,
    width: 24,
};

export default Timing;