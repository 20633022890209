import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux';
import rootReducer from './rootReducer';
import rootSagas from './rootSagas';
import {ThemeProvider} from 'styled-components';
import {createReduxStore, theme} from 'common';
import AppSwitch from './AppSwitch';

const App = () =>
    <ReduxProvider store={createReduxStore(rootReducer, rootSagas)}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AppSwitch/>
            </BrowserRouter>
        </ThemeProvider>
    </ReduxProvider>;

export default App;