import styled from 'styled-components/macro';
import {media} from 'common';

export const NavBarOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #242934;
  z-index: 9;
  opacity: .8;
  transition: opacity .25s ease-in-out;
  overflow: hidden;
  @media (min-width: ${1024 - 1}px) {
    display: none;
  }
`;

export const NavBarFullWidth = styled.div`
  @media (min-width: 1124px) {
    margin-left: calc(-100vw / 2 + 1160px / 2);
    margin-right: calc(-100vw / 2 + 1160px / 2);
  }
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);

  @media (max-width: ${1024 - 1}px) {
    border: none;
    margin: 0;
    display:flex;
    flex-direction: column;
  }
`;

export const MenuButtonContainer = styled.div`
  display: none;

  Icons.Menu {
    padding-top: 0.25rem;
  }
  
  span {
    padding-top: 0.375rem;
  }
  
  @media (max-width: ${1024 - 1}px) {
    display: flex;
    width: 4rem
  }
`;

export const MenuButton = styled.button`
  display: none;
  flex-direction: column;
  align-items: center;
  border: none;
  background: white;
  cursor: pointer;
  font-size: 10px;
  padding:unset;
  
  @media (max-width: ${1024 - 1}px) {
    display: flex;
  }
`;

export const CloseButton = styled.button`
  display: none;
  width: 68px;
  position: relative;
  padding: 1.1rem;
  border: none;
  background: white;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  cursor: pointer;

  @media (max-width: ${1024 - 1}px) {
    display: ${props => props.open ? 'flex' : 'none'};
  }
   @media (max-width: ${media.sm}px){
    padding-left: 1rem;
  }
`;

export const NavBarContainer = styled.div`
  @media (min-width: 1124px) {
    margin-left: calc(100vw / 2 - 1160px / 2);
    margin-right: calc(100vw / 2 - 1160px / 2);
  }
  @media (max-width: ${1024 - 1}px) {
    display: ${props => props.open ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 296px;
    background: white;
    z-index: 10;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s;
  }
`;

export const NavMenu = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: ${1024 - 1}px) {
    position: relative;
    flex-direction: column;
  }
`;

export const NavMenuItem = styled.li`
  padding: 0;
  margin: 0 2.5rem 0 0;
  border-bottom: ${props => props.selected ? '4px solid rgb(198, 12, 48)' : 'none'};

  a {
    display: block;
    height: 100%;
    padding: 24px 0 ${props => props.selected ? '20px' : '24px'};
    font-size: 16px;
    color: black;
    font-weight: ${props => props.selected ? 'bold' : '300'};
  }

  @media (max-width: ${1024 - 1}px) {
    color: rgb(36, 41, 52);
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    border-bottom: none;
    margin: 0;
    border-left: ${props => props.selected ? '4px solid rgb(198, 12, 48)' : 'none'};
    a {
      padding: 16px 8px 16px ${props => props.selected ? '16px' : '20px'};
      border-bottom: 1px solid rgb(224, 224, 224);
      font-weight: ${props => props.selected ? 'bold' : '500'};
    }

    :first-child {
      a {
        border-top: 1px solid rgb(224, 224, 224);
      }
    }
  }
`;

export const MobileNavMenuContainer = styled.div`
  display: flex;
  background: rgb(36, 41, 52);
  padding: 24px 20px;
  @media (min-width: ${1024 - 1}px) {
    display: none;
  }
`;

export const MobileNavMenuItem = styled.li`
  padding: 0;
  a {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
  }
  height: 28px;
`;