export const feedbackConfig = [
    {
        routes: ['' +
            '/dashboard',
            '/dashboard/applications',
            '/dashboard/overview',
            '/dashboard/services',
            '/dashboard/manage'],
        sourceApplication: 'Business Profile'
    },
    {
        routes: ['/dashboard/licences',
            '/licences',
            '/licences/details/*',
            '/licences/search'],
        sourceApplication: 'Licence Management'
    },
    {
        routes: ['/dashboard/opportunities'],
        sourceApplication: 'BusinessProfileOpportunities'
    },
    {
        routes: ['/notifications'],
        sourceApplication: 'BusinessProfileNotifications'
    }
];