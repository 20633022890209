import React from 'react';
import ContentLayout from '../../ContentLayout';
import {SideBarLayoutContainer, SideBar5050LayoutContainer} from './SideBarLayout.styled';

const SideBarLayout = ({children, ...other}) => {
    return (
        <ContentLayout {...other}>
            <SideBarLayoutContainer>
                {children}
            </SideBarLayoutContainer>
        </ContentLayout>
    );
};

SideBarLayout.propTypes = {
    ...ContentLayout.propTypes,
};

SideBarLayout.defaultProps = {
    ...ContentLayout.defaultProps,
};

// eslint-disable-next-line react/prop-types
export const SideBar5050Layout = ({children, ...other}) =>
    <ContentLayout {...other}>
        <SideBar5050LayoutContainer>
            {children}
        </SideBar5050LayoutContainer>
    </ContentLayout>;

export default SideBarLayout;
