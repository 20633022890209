/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
// Experimental. FYI, React hook rules deter this HOC setup, in favour of usig <Context>. However, we felt Context
// was rather cumbersome, and required too huge a change on every single component so went this way.
import React, {useEffect, useState} from 'react';
import {api} from 'common';
import env from '@beam-australia/react-env';

// https://test.snsw-d8.snsw.skpr.dev/jsonapi/microcontent/collection/?filter[label]=${collectionName}&include=contents
// FIXME: Setup Apigee for concierge
export const getDrupalContent = collectionName => api.getSecure(`${env('CONCIERGE_API_PATH')}/get-drupal-microcontent?collectionName=${collectionName}`)
  .then(res=>{
    const getAliasFromId = (id) => res.data[0].relationships.contents.data.find(x=>x.id === id).meta.alias;
    return res.included && res.included.reduce((a, t) => ({ ...a, [getAliasFromId(t.id)]: t.attributes.text || t.attributes.content.processed || t.attributes.content.value }), {});
});

const WithDrupalContent = (ComponentToRender) => props => {
  const [drupalData, setDrupalData] = useState();
  useEffect(() => {
        const fetchContent = () => {
         getDrupalContent(props.location.pathname).then(d=>setDrupalData(d));
        };
        fetchContent();
  }, [props.location.pathname, props.location.search]);
  const newProps = { ...props, ...drupalData };
  return <ComponentToRender {...newProps }/>;
};

export default WithDrupalContent;
