import omit from 'lodash/omit';

export const initialState = {
    initializing: true,
    type: null,
    fetching: false,
    hasError: false,
    currentState: null,
    historyStates: [],
    answersCollected: {},
};

export const storeKey = 'transaction';
export const actionKey = 'TX';

const transactionReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case `${actionKey}/INITIALIZE_REQUESTED`:
            return {
                ...initialState,
                initializing: true,
            };
        case `${actionKey}/INITIALIZE_SUCCESS`: {
            const {stateChart, currentState, historyStates, answersCollected, forms, transactionId, ...other} = payload;
            return {
                ...state,
                currentState,
                historyStates,
                answersCollected,
                transactionId,
                stateChart: {
                    ...stateChart,
                    ...other,
                    states: stateChart.states.reduce((acc, item) => {
                        const uiConfig = forms[item.stateId];
                        const options = uiConfig && uiConfig.options;
                        acc[item.stateId] = {
                            ...item,
                            ...uiConfig,
                            options: item.options && item.options.map((option) =>
                                ({...option, ...(options && options[option.text])})
                            )
                        };
                        return acc;
                    }, {}),
                },
                initializing: false,
            };
        }
        case `${actionKey}/SAVE_ANSWER_REQUEST`:
            return {...state, fetching: true,};
        case `${actionKey}/SAVE_DATA_REQUESTED`: {
            const {nextState, historyStates, savedAnswer, removedStates} = payload;
            const answersCollected = {...state.answersCollected, ...savedAnswer};
            return {
                ...state,
                currentState: nextState,
                historyStates,
                answersCollected: removedStates && removedStates.length
                    ? omit(answersCollected, removedStates)
                    : answersCollected,
                fetching: false
            };
        }
        case `${actionKey}/GOT_AN_ERROR`:
            return {...state, hasError: true,};
        case `${actionKey}/ERROR_HANDLED`:
            return {...state, hasError: false,};
        case `${actionKey}/UPDATE_ANSWERS`:
            return {
                ...state,
                answersCollected: {
                    ...state.answersCollected,
                    ...payload,
                }
            };
        case `${actionKey}/FETCH_REQUEST`:
            return {...state, fetching: true, hasError: false};
        case `${actionKey}/FETCH_SUCCESS`:
            return {...state, fetching: false};
        case `${actionKey}/FETCH_FAILURE`:
            return {...state, fetching: false, hasError: true, error: payload};
        default:
            return state;
    }
};

export default transactionReducer;
