import {Icons as SnswIcons} from '@snsw/react-component-library';
import Cost from './Cost';
import Timing from './Timing';
import Tick from './Tick';
import Download from './Download';
import TextBox from './TextBox';
import Warning from './Warning';
import Plus from './Plus';
import Power from './Power';
import Home from './Home';
import CaretDown from './CaretDown';
import Lock from './Lock';
import ExclamationCircle from './ExclamationCircle';
import Upload from './Upload';
import File from './File';
import ExclamationTriangle from './ExclamationTriangle';
import Mail from './Mail';
import NoResult from './NoResult';
import User from './User';
import Phone from './Phone';
import Location from './Location';
import Entity from './Entity';
import Search from './Search';
import ABN from './ABN';
import CaretUp from './CaretUp';
import Bars from './Bars';
import Notification from './Notification';
import Sort from './Sort';
import TickCircled from './TickCircled';
import Pencil from './Pencil';
import CheckCircle from './CheckCircle';
import CrossCircle from './CrossCircle';
import GoodMood from './GoodMood';
import Bin from './Bin';
import CopyLink from './CopyLink';
import ExpandIcon from './ExpandIcon';
import AccessTime from './AccessTime';
import Grading from './Grading';
import OpaqueLock from './OpaqueLock';
import ThumbsUp from './ThumbsUp';
import ThumbsDown from './ThumbsDown';
import {IconMenu as Menu} from '@snsw/react-component-library';
import {IconNotifications as Notifications} from '@snsw/react-component-library';
import {IconClose as Close} from '@snsw/react-component-library';
import {IconChevronDown} from '@snsw/react-component-library';
import {IconChevronRight} from '@snsw/react-component-library';
import {IconChevronUp} from '@snsw/react-component-library';
import {IconNotificationInfo} from '@snsw/react-component-library';
import {IconNotificationSuccess as NotificationSuccess} from '@snsw/react-component-library';
import {IconNotificationError as NotificationError} from '@snsw/react-component-library';

const Icons = {
    ...SnswIcons,
    AccessTime,
    ABN,
    Bars,
    CaretDown,
    CaretUp,
    CheckCircle,
    CopyLink,
    Cost,
    CrossCircle,
    Download,
    Entity,
    ExclamationCircle,
    ExclamationTriangle,
    ExpandIcon,
    File,
    GoodMood,
    Grading,
    Home,
    Location,
    Lock,
    Mail,
    NoResult,
    Notification,
    TextBox,
    OpaqueLock,
    Pencil,
    Phone,
    Plus,
    Power,
    Search,
    Sort,
    Tick,
    TickCircled,
    Timing,
    Upload,
    User,
    Warning,
    Bin,
    ThumbsUp,
    ThumbsDown,
    Menu,
    Notifications,
    Close,
    IconChevronDown,
    IconChevronUp,
    IconChevronRight,
    IconNotificationInfo,
    NotificationSuccess,
    NotificationError,
};

export default Icons;
