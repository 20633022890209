import {Route} from 'react-router';
import React from 'react';
import Navigation from '../components/routing/Navigation';
import {PROGRAM_CODES, PROGRAM_PATHS} from '../businessProgram/_common/constants';
import {redirectToExternalUrl} from 'common';

const SVOBRedirects = () => {
    const urlRedirectMaps = [
        ['/opportunities', '/dashboard/opportunities'],
        '/add-business',
        '/verification',
        '/licences/*',
        '/dashboard',
        '/dashboard/*',
        '/dine-and-discover/payments',
        '/first-lap/*',
        '/before-and-after-school-care/*',
        '/stay-and-rediscover/*',
        '/notifications',
        '/notifications/preferences',
        '/sbfv/*',
        '/micro-business-grant/*',
        '/job-saver/*',
        `${PROGRAM_PATHS[PROGRAM_CODES.NorthernMediumFloodRebate]}/*`,
        '/seniors-card/*',
        '/back-to-school/*',
    '/active-creative-kids/*'];

    const redirectToApp = (url, businessProfilePath = '/business-profile' ) => {
        window.location.replace(businessProfilePath + url);
        return null;
    };

    return <Navigation>
        {urlRedirectMaps.map((route,index) => {
            return !Array.isArray(route) ? <Route key={index} exact path={route} component={ ({match}) => redirectToApp( match.url) } />:
                <Route key={index} exact path={route[0]} component={ () => redirectToApp( route[1]) } />;
        })}

        <Route exact path={'/user/concierge'}
               component={() => redirectToApp( '/business-concierge', '')}/>

        <Route exact path={'/concierge'}
               component={() => redirectToApp( '/business-concierge', '')}/>

        <Route exact path={'/business-finder/*'}
              component={() => redirectToExternalUrl('https://vouchers.service.nsw.gov.au/vouchers/providers-search/')}/>
    </Navigation>;
};

export default SVOBRedirects;