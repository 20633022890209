import React from 'react';
import PropTypes from 'prop-types';
import PaddedLayout from './PaddedLayout';

const ContentLayout = ({children, backgroundColor}) =>
    <PaddedLayout justifyContent='none' backgroundColor={backgroundColor}>
        {children}
    </PaddedLayout>;

ContentLayout.propTypes = {
    children: PropTypes.node.isRequired,
    backgroundColor: PropTypes.string,
};

export default ContentLayout;
