import styled, {css} from 'styled-components/macro';
import PropTypes from 'prop-types';
import {layout} from 'styled-system';
import {media} from '@snsw/react-component-library';
import Block from '../../Blocks/Block.styled';

export const Flex = styled(Block)`
  display: flex;
  flex-grow: 1;
  flex-direction: ${props => props.direction || 'column'};
  justify-content: ${props => props.justifyContent || 'space-between'};
  align-items: ${props => props.alignItems || 'stretch'};
  ${props => props.gap && css`
    gap: ${props.gap};
  `};

  ${props => props.responsive && css`
    margin-left: -.5rem;
    margin-right: -.5rem;
    >div {
      margin-left: .5rem;
      margin-right: .5rem;
    }
    @media(min-width: ${media.xs}px) {      
      flex-direction: row;
    }
  `};

  ${layout};
`;

Flex.propTypes = {
    responsive: PropTypes.bool,
    direction: PropTypes.string,
    justifyContent: PropTypes.string,
    alignItems: PropTypes.string,
    gap: PropTypes.string
};
