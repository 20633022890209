import businessConnectApi from '../businessConnectApi';
import {put, takeEvery} from 'redux-saga/effects';
import {
    BUSINESS_CONNECT_EVENT_FAILURE,
    BUSINESS_CONNECT_EVENT_REQUEST,
    BUSINESS_CONNECT_EVENT_SUCCESS
} from './businessEventAction';

function* fetchEvents(action) {
    try {
        const payload = yield businessConnectApi.getEvents(action.payload);
        yield put({type: BUSINESS_CONNECT_EVENT_SUCCESS, payload: payload});
    } catch (e) {
        yield put({type: BUSINESS_CONNECT_EVENT_FAILURE});
    }
}

export default function* businessEventSaga() {
    yield takeEvery(BUSINESS_CONNECT_EVENT_REQUEST, fetchEvents);
}
