import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const withBusinessPreSelection = Component => {

    const WrapComponent = ({updateSelectedBusinessId, ...props}) => {
        const {user, history, location} = props;

        useEffect(() => {
            const businessPreselectedFromUrl = extractPreselectedBusinessFromUrlParams(location);

            if (businessPreselectedFromUrl) {
                const {identifier, businessName} = businessPreselectedFromUrl;

                const matchedBusiness = user.businesses.find((business) =>
                    business.identifier === identifier && business.businessName === businessName,
                );

                if (matchedBusiness) {
                    updateSelectedBusinessId(matchedBusiness.id);
                }

                history.replace(location.pathname);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <Component {...props} />;
    };

    const extractPreselectedBusinessFromUrlParams = (location, tryDecodedQuery = false) => {
        const queryUrl = tryDecodedQuery ? decodeURIComponent(location.search) : location.search;

        const params = new URLSearchParams(queryUrl);
        const identifier = params.get('identifier');
        const businessName = params.get('businessName');

        if (identifier && businessName) {
            return {
                identifier,
                businessName,
            };
        } else if (!tryDecodedQuery) {
            return extractPreselectedBusinessFromUrlParams(location, true);
        } else {
            return null;
        }
    };

    const mapStateToProps = ({user}) => {
        return {user: user};
    };

    const mapDispatchToProps = (dispatch) => ({
            updateSelectedBusinessId: (selectedBusinessId) => dispatch({
                type: 'SELECTED_BUSINESS_ID_UPDATED',
                payload: {selectedBusinessId},
            }),
        }
    );

    WrapComponent.propTypes = {
        user: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        updateSelectedBusinessId: PropTypes.func.isRequired,
    };

    return connect(mapStateToProps, mapDispatchToProps)(WrapComponent);
};

export default withBusinessPreSelection;
