import layoutStore from './layoutStore';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

export default (state = layoutStore, action) => {
    switch (action.type) {
        case SHOW_ERROR:
            return {
                ...state,
                showError: true,
            };
        case HIDE_ERROR:
            return {
                ...state,
                showError: false,
            };
        case RESET_ERROR:
            return {
                ...state,
                showError: false,
                errorType: ''
            };
        default:
            return state;
    }
};
