import tooltipStore from './tooltipStore';

export const TOOLTIP_UPDATE_REQUEST = 'TOOLTIP_UPDATE_REQUEST';

export default (state = tooltipStore, action) => {
    switch (action.type) {
        case TOOLTIP_UPDATE_REQUEST : {
            return {
                ...state,
                initialised: true,
                active: action.payload.activeTooltips,
            };
        }
        default: {
            return state;
        }
    }
};