export default (email) => {
    // Code snippet added to fix potential timing issue (load script executed after remove is executed)
    const loggedInHeaders = document.getElementsByClassName('logged-in-header');
    if(loggedInHeaders.length > 0) {
        return;
    }
    var cssRules = '.translate__notice a { color:#2e5299 !important; text-decoration: underline; }';
    var styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(cssRules));
    const businessProfilePath = '/dashboard';
    const serviceAccountNav = document.getElementById('block-global-header-account-menu');
    const serviceAccountNavMobile = document.getElementById('block-global-header-mobile-menu');
    const MyServiceBusinessAcctElement = `<li class="mybusiness"><a href=${businessProfilePath}>MyServiceNSW Business</a></li>`;
    const LogoutElement = email ? '<li class="mybusiness-logout"><a href="/logout">Log out</a></li>' : '';
    if (serviceAccountNav || serviceAccountNavMobile) {
        serviceAccountNav.appendChild(styleElement);
        serviceAccountNavMobile.appendChild(styleElement);

        const logoutLiDesktop = serviceAccountNav.querySelector('.inline-nav > ul li.is-logout');
        const logoutLiMobile = serviceAccountNavMobile.querySelector('.mobile-nav > ul li.is-logout');
        const ulElement = serviceAccountNav.querySelector('.inline-nav > ul');
        const ulElementMobile = serviceAccountNavMobile.querySelector('.mobile-nav > ul');
        if (logoutLiDesktop && ulElement) {
            logoutLiDesktop.remove();
            ulElement.insertAdjacentHTML('beforeend', MyServiceBusinessAcctElement);
            ulElement.insertAdjacentHTML('beforeend', LogoutElement);
        } else {
            console.info('Nav elements not found');
        }

        if (logoutLiMobile && ulElementMobile) {
            logoutLiMobile.remove();
            ulElementMobile.insertAdjacentHTML('beforeend', MyServiceBusinessAcctElement);
            ulElementMobile.insertAdjacentHTML('beforeend', LogoutElement);
        } else {
            console.info('Nav elements not found');
        }
    }
};

export const removeGlobalNav = () => {
    const globalHeaders = document.getElementsByClassName('global-header');
    const globalFooters = document.getElementsByClassName('global-footer');
    const globalFooterAcks = document.getElementsByClassName('settings-tray-editable');

    if (globalHeaders && globalHeaders.length) {
        Array.from(globalHeaders).forEach(header => {
            header.remove();
        });
    }
    if (globalFooters && globalFooters.length) {
        Array.from(globalFooters).forEach(footer => {
            footer.remove();
        });
    }
    if (globalFooterAcks && globalFooterAcks.length) {
        Array.from(globalFooterAcks).forEach(ack => {
            ack.remove();
        });
    }
};