import React from 'react';
import {color, layout} from 'styled-system';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const FileSvg = ({className, color, theme, isSolid}) => {
    return isSolid ?
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
            <path fill={theme.colors[color] || theme.colors.blue}
                  d='M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z'/>
        </svg> :
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
            <path fill={theme.colors[color] || theme.colors.blue}
                  d='M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z'/>
        </svg>;
};

const File = styled(FileSvg)`
  ${color}
  ${layout}
`;

File.defaultProps = {
    height: 12,
    width: 12,
    isSolid: true,
};

export default withTheme(File);