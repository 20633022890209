import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router';
import {PageLayout} from 'common';
import ProtectedPage from './ProtectedPage';
import Page from './Page';

const PageRoute = ({component, layout, secure, scrollToTop, showSignIn, industry, ...otherProps}) => {
    const pageProps = {
        scrollToTop,
        showSignIn,
        component,
        layout,
    };
    return <Route {...otherProps} render={props => secure ?
        <ProtectedPage {...otherProps} {...props} {...pageProps}/> :
        <Page {...otherProps} {...props} {...pageProps} industryName={industry}/>}
    />;
};

PageRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]).isRequired,
    layout: PropTypes.func,
    secure: PropTypes.bool,
    onboarded: PropTypes.bool,
    exact: PropTypes.bool,
    showSignIn: PropTypes.bool,
    scrollToTop: PropTypes.bool,
    industry: PropTypes.string,
};

PageRoute.defaultProps = {
    layout: PageLayout.GREY,
    secure: false,
    exact: true,
    showSignIn: true,
    scrollToTop: true,
    onboarded: false,
};

export default PageRoute;
