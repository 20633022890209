import React from 'react';
import PropTypes from 'prop-types';
import {IconChevronLeft} from 'common';
import env from '@beam-australia/react-env';

const SnswWebsiteLink = ({icon = true}) => {
    const websiteLink = env('NODE_ENV') === 'production' ?
        'https://www.service.nsw.gov.au' :
        'https://stg.snsw-d8.snsw.skpr.dev';
    return (
        <a href={websiteLink} style={{display: 'flex'}}>
            {icon && <div style={{marginRight: '12px'}}><IconChevronLeft color='white' height={10} width={10}/></div>}
            <span>Back to Service NSW website</span>
        </a>
    );
};

SnswWebsiteLink.propTypes = {
    icon: PropTypes.bool,
};

export default SnswWebsiteLink;