import React from 'react';
import {
    BrandingLogo,
    FullWidthLogoBar,
    FullWidthStatusBar,
    LogoBarContainer,
    MaxWidthContainer,
    PickerPlaceholderContainer,
    RightSection,
    StatusBarContainer
} from './HeaderFooter.styled';
import brandingLogo from '../images/branding-logo.svg';
import NotificationLink from './NotificationLink';
import NavMenuButton from './navMenu/NavMenuButton';
import SnswWebsiteLink from './SnswWebsiteLink';
import SnswMyAccountLink from './SnswMyAccountLink';
import PropTypes from 'prop-types';
import BusinessPicker from './businessPicker/BusinessPicker';

export const LoggedInHeader = ({user, history, location}) => {
    const {selectedBusinessId, businesses} = user;
    return (
        <div className='logged-in-header'>
            <FullWidthStatusBar>
                <MaxWidthContainer>
                    <StatusBarContainer>
                        <div>
                            <SnswWebsiteLink/>
                        </div>
                        <RightSection>
                            <SnswMyAccountLink/>
                            <a href='/logout'>Log out</a>
                        </RightSection>
                    </StatusBarContainer>
                </MaxWidthContainer>
            </FullWidthStatusBar>
            <FullWidthLogoBar>
                <MaxWidthContainer>
                    <LogoBarContainer>
                        <NavMenuButton/>
                        <BrandingLogo>
                            <a href={'https://www.service.nsw.gov.au/'}>
                                <img src={brandingLogo} alt={'ServiceNSW Logo'}/>
                            </a>
                        </BrandingLogo>
                        <RightSection>
                            <PickerPlaceholderContainer/>
                            <NotificationLink/>
                        </RightSection>
                    </LogoBarContainer>
                </MaxWidthContainer>
            </FullWidthLogoBar>
            <MaxWidthContainer style={{position: 'relative'}}>
                <BusinessPicker user={user} businesses={businesses} selectedBusinessId={selectedBusinessId}
                                history={history} location={location}/>
            </MaxWidthContainer>
        </div>
    );
};

LoggedInHeader.propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

