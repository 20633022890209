import styled from 'styled-components/macro';
import {colors, th, Button, media} from '@snsw/react-component-library';

const StyledButton = styled(Button);

export const Sticky = styled.div`
  position: sticky;
  top: 1rem;
`;

export const StickyBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -2px 4px 0px rgba(177, 190, 196, 0.25);
  background: ${colors.white};
  padding: ${th[1.5]} 0;  
  margin: 0 auto; 
  z-index: 1;

  &.static-sticky-bottom {
    position: static;
    border-radius: 0.375rem;
  }

  &.fixed-sticky-bottom {
    position: fixed;
  }
  
  ${StyledButton} {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  
  @media (max-width: ${media.xs}px) {
    flex-direction: column;
    align-items: center;
  
    ${StyledButton} {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`;

export const StickyDisplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media (max-width: ${media.xs}px) {
    flex-direction: column;
  }
`;