import businessConnectApi from './businessConnectApi';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
    BUSINESS_CONNECT_FILTER_FAILURE,
    BUSINESS_CONNECT_FILTER_REQUEST,
    BUSINESS_CONNECT_FILTER_SUCCESS
} from './businessConnectFilterAction';

const formatFilterOptions = (filterTypes, data) => data.reduce((acc, options, index) => {
    return {
        ...acc,
        [filterTypes[index]]: options,
    };
}, {});

function* fetchBusinessConnectFilters(action) {
    try {

        const result = yield all(
            action.payload.map((filterType) =>
                call(businessConnectApi.getFilterOptions, filterType)
            )
        );
        const formattedData = formatFilterOptions(action.payload, result);
        yield put({type: BUSINESS_CONNECT_FILTER_SUCCESS, payload: formattedData});
    } catch (e) {
        yield put({type: BUSINESS_CONNECT_FILTER_FAILURE});
    }
}

export default function* businessConnectFilterSaga() {
    yield takeEvery(BUSINESS_CONNECT_FILTER_REQUEST, fetchBusinessConnectFilters);
}
