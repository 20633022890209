import {actionKey} from './transactionReducer';

export const initialize = (stateChart) => ({
    type: `${actionKey}/INITIALIZE_REQUESTED`,
    payload: stateChart,
});

export const initializeSuccess = (newState) => ({
    type: `${actionKey}/INITIALIZE_SUCCESS`,
    payload: newState,
});

export const errorHandled = () => ({
    type: `${actionKey}/ERROR_HANDLED`,
});

export const saveAnswerRequested = (stateId, answer, callback) => ({
    type: `${actionKey}/SAVE_ANSWER_REQUEST`,
    payload: {stateId, answer, callback},
});

export const saveDataRequested = (newState) => ({
    type: `${actionKey}/SAVE_DATA_REQUESTED`,
    payload: newState,
});

export const updateAnswers = (answers) => ({
    type: `${actionKey}/UPDATE_ANSWERS`,
    payload: answers
});

export const fetchRequest = () => ({
    type: `${actionKey}/FETCH_REQUEST`,
});

export const fetchSuccess = () => ({
    type: `${actionKey}/FETCH_SUCCESS`,
});

export const fetchFailure = error => ({
    type: `${actionKey}/FETCH_FAILURE`,
    payload: error,
});
