import React from 'react';
import PropTypes from 'prop-types';
import ContentLayout from '../../ContentLayout';
import {GelLandingPageLayoutContainer} from './GelLandingPageLayout.styled';

const GelLandingPageLayout = ({children, invert}) => {
    return (
        <>
            <GelLandingPageLayoutContainer
                aria-label='Main content'
                invert={invert}
                leftSectionName={invert ? 'side-bar' : 'content'}
                rightSectionName={invert ? 'content' : 'side-bar'}
            >
                {children}
            </GelLandingPageLayoutContainer>
        </>
    );
};

GelLandingPageLayout.propTypes = {
    ...ContentLayout.propTypes,
    invert: PropTypes.bool,
};

GelLandingPageLayout.defaultProps = {
    ...ContentLayout.defaultProps,
    invert: false,
};

export default GelLandingPageLayout;
