import React from 'react';
import styled from 'styled-components/macro';
import {color, layout, space} from 'styled-system';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const CrossCircleSvg = ({className, size, color, theme}) => (
    <svg width={`${size}px`} height={`${size}px`} className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <defs>
            <path d='M12,0 C5.4,0 0,5.4 0,12 C0,18.6 5.4,24 12,24 C18.6,24 24,18.6 24,12 C24,5.4 18.6,0 12,0 Z M17.8781256,16.2 L16.2,17.8781256 L12,13.6781256 L7.8,17.8781256 L6.1218756,16.2 L10.3218756,12 L6.1218756,7.8 L7.8,6.1218756 L12,10.3218756 L16.2,6.1218756 L17.8781256,7.8 L13.6781256,12 L17.8781256,16.2 Z' id='path-1'></path>
        </defs>
        <g id='UI' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='0.2.11-LG---Onboarding---All-failed-to-accociate'
               transform='translate(-152.000000, -458.000000)'>
                <g id='icon/status/error' transform='translate(152.000000, 458.000000)'>
                    <mask id='mask-2' fill='white'>
                        <use xlinkHref='#path-1'></use>
                    </mask>
                    <use id='Shape' fill={theme.colors[color] || color} fillRule='evenodd' xlinkHref='#path-1'></use>
                </g>
            </g>
        </g>
    </svg>
);

const CrossCircle = styled(withTheme(CrossCircleSvg))`
  ${color}
  ${layout}
  ${space}
`;

CrossCircle.defaultProps = {
    color: '#B81237',
    size: 24,
};

export default CrossCircle;