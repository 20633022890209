import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import PageRoute from '../routing/PageRoute';
import {PageLayout} from 'common';
import {Switch} from 'react-router';
import NotFound from '../routing/NotFound';
import LoggedInBaseLayout from '../../dashboard/LoggedInBaseLayout';

export const LicenceSearchPageLazy = React.lazy(() => import('./LicenceSearchPage'));
export const LicenceDetailsPageLazy = React.lazy(() => import('./LicenceDetailsPage'));
export const LicenceRetrieverRedirectionPageLazy = React.lazy(() => import('./LicenceRetrieverRedirection'));

export const LicenceManagementNavigation = (props) => {
    return (
        <Switch>
            <PageRoute
                    secure
                    layout={LoggedInBaseLayout}
                    path='/licences/search'
                    component={LicenceSearchPageLazy}
                    {...props}
            />
            <PageRoute
                secure
                layout={LoggedInBaseLayout}
                path='/licences/details/:licenceId'
                component={LicenceDetailsPageLazy}
                {...props}
            />
            <PageRoute
                secure
                layout={LoggedInBaseLayout}
                path='/licences/retrieve/:licenceNumber/:licenceType/:businessId/:industry?'
                component={LicenceRetrieverRedirectionPageLazy}
                {...props}
            />
            <PageRoute
                layout={PageLayout.WHITE}
                path='/licences/search/*'
                component={NotFound}
                {...props}
            />
        </Switch>
    );
};

LicenceManagementNavigation.propTypes = {
    businessId: PropTypes.string,
    businessName: PropTypes.string,
    businesses: PropTypes.array,
    history: PropTypes.object.isRequired,
    fullHeight: PropTypes.bool,
};

LicenceManagementNavigation.defaultProps = {
    fullHeight: true,
};

export const mapStateToProps = ({user}) => {
    const {selectedBusinessId, businesses} = user;
    const selectedBusiness = businesses.find(business => business.id === selectedBusinessId);
    return ({
        businessId: selectedBusinessId,
        businessName: selectedBusiness ? selectedBusiness.name : '',
        businesses: businesses
    });
};

export default connect(mapStateToProps)(LicenceManagementNavigation);