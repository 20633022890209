import styled from 'styled-components/macro';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import FullWidthContent from '../components/FullWidthContent';
import PageBreadcrumb from '../components/PageBreadcrumb';
import Content from '../components/Content';
import {EtdbButton as Button, media, th} from '@snsw/react-component-library';
import {StickyBlock} from '../../../Blocks/StickyBlock.styled';
import MobileActionHeader from '../components/MobileActionHeader';
import PreFooter from '../components/PreFooter';
import Footer from '../components/Footer';

export const MySNSWLandingPageLayoutContainer = styled.main`
  overflow-x: hidden;
  background-color: ${props => props.theme.colors.white};
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc((100vw - 75rem)/2) calc(75rem * 0.7) calc(75rem * 0.3) calc((100vw - 75rem)/2);
  grid-template-columns: auto calc(62.9rem * 0.7) calc(68.5rem * 0.3) auto;
  grid-template-areas:
    ".        breadcrumb                breadcrumb                  ."
    ".        header                    header                      ."
    ".        navigation                navigation                  ."
    ".        full-width-content        full-width-content          ."
    ".        content                   side-bar                    ."
    ".        pre-footer                side-bar                    ."
    "footer   footer                    footer                      footer";

  @media (max-width: ${media.lg}px) {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    grid-template-areas:
      "breadcrumb"
      "header"
      "mobile-action-header"
      "navigation"
      "full-width-content"
      "content"
      "side-bar"
      "pre-footer"
      "footer";
  }

  ${PageBreadcrumb}, ${Header} {
    background-color: ${props => props.theme.colors.white};

    padding-top: ${th[1.5]};
    padding-right: ${th[3]};

    @media (max-width: ${media.xl}px) {
      padding-top: ${th[1.5]};
      padding-left: ${th[1]};
    }
  }

  ${PageBreadcrumb} {
    grid-area: breadcrumb;

    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;

     @media (max-width: ${media.lg}px) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
     }
  }

  ${Header} {
    grid-area: header;
    padding-bottom: ${th[2.5]};

    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;

    @media (max-width: ${media.lg}px) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
     }

    :not(:first-child) {
      padding-top: ${th[1.5]};
    }

    @media (max-width: ${media.sm}px) {
      padding-bottom: ${th[2]};
    }

    > * {
      width: 70%;
      @media (max-width: ${media.lg}px) {
        width: 100%;
      }
    }

    h1 + p, h2 + p, h3 + p, h4 + p, h5 + p {
      margin-top: ${th[1.5]};

      @media (max-width: ${media.xs}px) {
        margin-top: ${th[1]};
      }
    }
  }

  ${MobileActionHeader}{
    grid-area: mobile-action-header;

    @media (max-width: ${media.lg}px) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
     }

    display: none;
    background-color: ${props => props.theme.colors.greyBackground};
    margin-top: -${th[2.5]};
    @media (max-width: ${media.sm}px) {
      margin-top: -${th[2]};
    }
    padding: ${th[1.5]};

    @media (max-width: ${media.lg}px) {
      display: unset;
    }
  }

  ${Navigation} {
    grid-area: navigation;
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    -ms-grid-row: 3;

    grid-row: 3;
    border-radius: 0;

    position: relative;

    @media (min-width: ${media.lg}px) {
      padding-left: ${th[1]};
    }

    @media (max-width: ${media.lg}px) {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        grid-column-span: 4;
     }
  }

  ${FullWidthContent} {
    grid-area: full-width-content;
    grid-row: 4;
    padding-left: ${th[1]};
    padding-right: ${th[1]};
  }

  ${Content} {
    grid-area: content;
    -ms-grid-column: 2;
    -ms-grid-row: 5;
    grid-row: 5;

    @media (max-width: ${media.lg}px) {
      -ms-grid-row: 6;
      -ms-grid-column: 1;
    }

    @media (min-width: ${media.xl}px) {
       padding: ${th[2.5]} ${th[1]};
    }

    @media (max-width: ${media.xl-1}px) {
        padding: ${th[2.5]} ${th[1]};
    }

    @media (max-width: ${media.sm}px) {
        padding: ${th[2.5]} ${th[1]};
    }

    @media (max-width: ${media.xs}px) {
        padding: ${th[2]} ${th[1]};
    }
  }

  ${SideBar} {
    grid-area: side-bar;
    -ms-grid-column: 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 2;

    @media (max-width: ${media.lg}px) {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
        width: 100%;
    }

    @media (min-width: ${media.xl}px) {
        padding: ${th[2]} ${th[1]};
    }

    @media (max-width: ${media.xl-1}px) {
        padding: ${th[2]} ${th[1]};
    }

    @media (max-width: ${media.sm}px) {
        padding: ${th[1.5]} ${th[1]};
    }

    @media (max-width: ${media.xs}px) {
        padding: ${th[1]} ${th[1]};
    }
  }

  ${PreFooter} {
    grid-area: pre-footer;
    padding-left: ${th[3]};
    padding-right: ${th[2]};

    -ms-grid-column: 2;
    -ms-grid-row: 6;

    @media (max-width: ${media.lg}px) {
        -ms-grid-row: 8;
        -ms-grid-column: 1;
     }

    @media (max-width: ${media.sm}px) {
      padding-left: ${th[1.5]};
      padding-right: ${th[1.5]};
    }
  }

  ${Footer} {
    grid-area: footer;

    flex-direction: column;
    display: flex;
    margin-bottom: 0;

    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 7;

    @media (max-width: ${media.lg}px) {
        -ms-grid-row: 9;
        -ms-grid-column: 1;
     }

    @media (max-width: ${media.sm}px) {
      ${StickyBlock} {
        height: unset;
        min-height: 7rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      ${StickyBlock.SectionBlockContent} {
        flex-direction: column;
      }

      ${Button} {
        width: 100%;
      }
    }
  }
`;
