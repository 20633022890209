import {
    BUSINESS_CONNECT_EVENT_FAILURE,
    BUSINESS_CONNECT_EVENT_REQUEST,
    BUSINESS_CONNECT_EVENT_SUCCESS
} from './businessEventAction';

const initialState = {
    events: [],
    fetching: false,
    apiError: false,
};

const businessEventReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_CONNECT_EVENT_REQUEST:
            return {
                ...state,
                fetching: true,
                apiError: false,
            };
        case BUSINESS_CONNECT_EVENT_SUCCESS:
            return {
                ...state,
                events: action.payload,
                fetching: false,
                apiError: false,
            };

        case BUSINESS_CONNECT_EVENT_FAILURE:
            return {
                ...state,
                fetching: false,
                apiError: true,
            };
        default:
            return state;
    }
};

export default businessEventReducer;
