import styled from 'styled-components/macro';
import {media} from '@snsw/react-component-library';

const MobileActionHeader = styled.div`

@media (max-width: ${media.lg}px) {
  button {
    width: 100%
  } 
}
`;
export default MobileActionHeader;