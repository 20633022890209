import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router';
import env from '@beam-australia/react-env';
import {ComponentLoader, PageLayout, redirectToExternalUrl, SLORedirect} from 'common';
import Navigation from '../components/routing/Navigation';
import PageRoute from '../components/routing/PageRoute';
import LazyConstructionNavigation from '../construction/LazyConstructionNavigation';
import LazyCrsbNavigation from '../crsb/LazyCrsbNavigation';
import VerificationRedirection from '../components/account/RelationshipVerification/VerificationRedirection';
import {thumbsUpDownForceBottom} from '../components/utilities/externalScriptHandler/thumbs-up-down';
import withDrupalContent, {getDrupalContent} from './withDrupalContent';
import {stayAndRediscoverBasePath} from '../businessProgram/stayAndRediscover/stayAndRediscoverPaths';
import LicenceManagementNavigation from '../components/account/LicenceManagementNavigation';
import LoggedInBaseLayout from '../dashboard/LoggedInBaseLayout';
import boolEnv from '../components/utilities/boolEnv';
import {PROGRAM_CODES, PROGRAM_PATHS} from '../businessProgram/_common/constants';
import withBusinessPreSelection from './withBusinessPreSelection';
import SVOBRedirects from './SvobRedirects';
import {MaintenancePageLazy} from './MaintenanceRedirects';
import WhiteLayout from 'common/build/layout/WhiteLayout';

const constructionBasePath = '/housing-construction';
const cafeBasePath = '/crsb';

export const HaveYourSayLandingPageLazy = React.lazy(() => import('../haveYourSay/HaveYourSayLandingPage'));
export const TermsAndConditionsPageLazy = React.lazy(() =>
    import('../termsAndConditions/TermsAndConditionsPage'),
);
export const ServiceNotAvailableLazy = React.lazy(() => import('../components/ui/ServiceNotAvailable'));

export const ConciergeLazy = React.lazy(() => import('../conceirge/Concierge'));

export const TransactionNavigationLazy = React.lazy(() =>
    import(/* webpackChunkName: 'tx' */ './TransactionNavigation'),
);
export const AddBusinessPageLazy = React.lazy(() =>
    import(/* webpackChunkName: 'dashboard' */ '../dashboard/AddBusinessPage'),
);
export const ManageBusinessPageLazy = React.lazy(() =>
    import(/* webpackChunkName: 'dashboard' */ '../dashboard/ManageBusinessPage'),
);
export const NotificationsPageLazy = React.lazy(() => import('../components/account/Notification/NotificationsPage'));
export const NotificationsPreferencePageLazy = React.lazy(() =>
    import('../components/account/Notification/NotificationsPreferencePage'),
);
export const RedirectPageLazy = React.lazy(() => import('./RedirectPage'));
export const LicenceManagerLazy = React.lazy(() =>
    import('../components/account/Licence/MultiBizLicenceManager'),
);
export const LicenceCategoryLazy = React.lazy(() =>
    import('../components/account/Licence/MultiBizLicenceCategory'),
);
export const BizLicenceSearchLazy = React.lazy(() =>
    import('../components/account/Licence/MultiBizLicenceSearch'),
);
export const DineAndDiscoverNavigationLazy = React.lazy(() =>
    import('../businessProgram/dineAndDiscover/DineAndDiscoverNavigation'),
);
export const BusinessDashboardNavigationLazy = React.lazy(() =>
    import('../dashboard/BusinessDashboardNavigation'),
);
export const FirstLapNavigationLazy = React.lazy(() =>
    import('../businessProgram/firstLap/FirstLapNavigation'),
);
export const ActiveKidsNavigationLazy = React.lazy(() =>
    import('../businessProgram/activeKids/ActiveKidsNavigation'),
);
export const BASCNavigationLazy = React.lazy(() =>
    import('../businessProgram/basc/BASCNavigation'),
);
export const StayAndRediscoverNavigationLazy = React.lazy(() =>
    import('../businessProgram/stayAndRediscover/StayAndRediscoverNavigation'),
);
export const SmallBusinessFeeVoucherNavigationLazy = React.lazy(() =>
    import('../businessProgram/smallBusinessFeeVoucher/SmallBusinessFeeVoucherNavigation'),
);
export const MicroBusinessGrantNavigationLazy = React.lazy(() =>
    import('../businessProgram/microBusiness/MicroBusinessGrantNavigation'),
);
export const JobSaverNavigationLazy = React.lazy(() =>
    import('../businessProgram/jobSaver/JobSaverNavigationWithBusiness'),
);
export const NorthernMediumFloodRebateLazy = React.lazy(() =>
    import('../businessProgram/mediumfloodgrant/NorthernMediumFloodRebateNavigation'),
);
export const SeniorsCardProgramLazy = React.lazy(() =>
    import('../businessProgram/seniorsCard/SeniorsCardProgramNavigation'),
);
export const BackToSchoolProgramLazy = React.lazy(() =>
    import('../businessProgram/backToSchool/BackToSchoolNavigation'),
);

const OUTAGE_BANNER_TYPES = ['noticeBanner', 'outageBanner'];

class GlobalNavigation extends React.Component {
    componentDidMount() {
        getDrupalContent('all-pages').then((resultObject = {}) =>
            OUTAGE_BANNER_TYPES.forEach((bannerType) => {
                const elmMatchingBannerType = document.getElementById(bannerType);
                if (resultObject[bannerType] && elmMatchingBannerType) {
                    elmMatchingBannerType.innerHTML = resultObject[bannerType];
                }
            }),
        );
        if (this.isExternalPath) {
            window.location.href = this.props.location.pathname;
        }
    }

    componentDidUpdate(prevProps) {
        const {
            location: {pathname},
        } = this.props;
        if (prevProps.location.pathname !== pathname) {
            if (this.isExternalPath) {
                window.location.href = pathname;
            }
        }
    }

    get isExternalPath() {
        const {location: {pathname}, externalPaths} = this.props;
        return externalPaths.some(externalPath => pathname.startsWith(externalPath));
    }

    render() {
        const {
            match,
        } = this.props;
        if (this.isExternalPath) {
            return <ComponentLoader fullpage />;
        }
        if (match.url && !match.url.includes('land-tax-relief') && !match.url.includes('business-finder')) {
            setTimeout(() => thumbsUpDownForceBottom(), 1250);
        }
        // Temporarily using setTimeout

        return (
            <Suspense fallback={<ComponentLoader fullPage />}>
                {boolEnv('ENABLE_MAINTENANCE') ? <Navigation>
                        <PageRoute
                            layout={WhiteLayout}
                            path={'/*'}
                            component={MaintenancePageLazy}
                        />
                    </Navigation> :
                    <Navigation>
                        {match.url !== '/' && /\/$/.test(match.url) && (
                            <Redirect data-test='trailing-slash' to={match.url.slice(0, -1)} />
                        )}
                        {/*Legacy URLs - DO NOT DELETE */}
                        <Redirect exact from={'/housing-construction/todo'} to={constructionBasePath} />
                        <Redirect
                            exact
                            from='/how-to-open-or-grow-a-cafe/development-application'
                            to={`${cafeBasePath}/da`}
                        />
                        <Redirect
                            exact
                            from='/:industry/transactions/development-consent'
                            to='/transactions/development-consent'
                        />
                        <Redirect
                            exact
                            from='/:industry/transactions/liquor-licences-nsw'
                            to='/transactions/liquor-licences-nsw'
                        />

                        <PageRoute
                            layout={PageLayout.NONE}
                            exact
                            path='/handle-brs-abn-referral'
                            component={({history}) => {
                                history.push('/');
                                return <div />;
                            }}
                        />

                        <Route path='/business-support-general'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/business/business-support')} />

                        <Route path='/payroll-tax'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/payroll-tax-business')} />

                        <Route path='/gst-registration'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/guide/goods-and-services-tax-gst-businesses')} />

                        <Redirect
                            exact
                            from='/how-to-open-or-grow-a-cafe'
                                to={`${cafeBasePath}/how-to-open-or-grow-a-cafe`}
                            />

                        <Redirect exact from='/guides' to={`${cafeBasePath}/guides`} />
                        <Route path={cafeBasePath} component={LazyCrsbNavigation} />
                        <Route path={constructionBasePath} component={LazyConstructionNavigation} />

                        <Route path='/waste-management'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/guide/waste-management-businesses')} />

                        <Route path='/whs'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/guide/work-health-and-safety-whs-business')} />

                        <Route path='/closing-a-business'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/guide/closing-business')} />

                        <Route path='/financial-help'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/business/manage-and-grow#grants')} />

                        <Redirect exact from='/outdoor-dining' to={`${cafeBasePath}/od`} />

                        <Route exact path='/'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/business')} />

                        <Route exact path={'/mobile'}
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/service-nsw-for-business-mobile-app')} />

                        <Route path={'/logout'} component={SLORedirect} />

                        <Route path='/business-guides'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/business')} />

                        <PageRoute
                            layout={PageLayout.WHITE}
                            path='/service-unavailable'
                            component={ServiceNotAvailableLazy} />

                        <Route path='/councils'
                               component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/business/councils')} />
                        <Route path='/transactions' component={TransactionNavigationLazy} />
                        <PageRoute
                            exact={true}
                            path='/redirect-page'
                            component={RedirectPageLazy}
                        />

                        {/* SVOB/MYBUSINESS REDIRECT SWITCH */}

                        {boolEnv('ENABLE_SVOB') ? <SVOBRedirects /> :

                            <Navigation>
                                {/* MYBUSINESS ROUTES */}
                                <Route path='/business-profile'
                                       component={() => redirectToExternalUrl('https://www.service.nsw.gov.au/business/business-profile')} />

                                <PageRoute
                                    layout={PageLayout.GREY_CONTENT}
                                    path='/haveyoursay'
                                    component={HaveYourSayLandingPageLazy}
                                />
                                <PageRoute
                                    secure
                                    layout={LoggedInBaseLayout}
                                    path='/add-business'
                                    component={AddBusinessPageLazy}
                                />
                                <PageRoute
                                    secure
                                    layout={PageLayout.NONE}
                                    path='/verification'
                                    component={VerificationRedirection}
                                />
                                <Redirect
                                    exact
                                    from='/opportunities'
                                    to='/dashboard/opportunities'
                                />
                                <Route path='/licences/*' component={LicenceManagementNavigation} />
                                <Route path='/dine-and-discover' component={DineAndDiscoverNavigationLazy} />
                                <Route path='/dashboard' component={BusinessDashboardNavigationLazy} />
                                <Route path='/first-lap' component={FirstLapNavigationLazy} />
                                <Route path='/before-and-after-school-care' component={BASCNavigationLazy} />
                                <Route path={stayAndRediscoverBasePath}
                                       component={StayAndRediscoverNavigationLazy} />
                                <Route path='/business-finder' component={StayAndRediscoverNavigationLazy} />
                                <Route path='/sbfv' component={SmallBusinessFeeVoucherNavigationLazy} />
                                <Route path='/micro-business-grant' component={MicroBusinessGrantNavigationLazy} />
                                <Route path='/job-saver' component={JobSaverNavigationLazy} />
                                <Route path={`${PROGRAM_PATHS[PROGRAM_CODES.NorthernMediumFloodRebate]}`}
                                       component={NorthernMediumFloodRebateLazy} />
                                <Route path='/seniors-card' component={SeniorsCardProgramLazy} />
                                <Route path='/back-to-school' component={BackToSchoolProgramLazy} />
                                {env('ENABLE_NOTIFICATION_PREFERENCES') === 'true' && <PageRoute
                                    secure
                                    layout={PageLayout.WHITE}
                                    path='/notifications/preferences'
                                    component={NotificationsPreferencePageLazy}
                                    fullHeight
                                />}
                                <PageRoute
                                    secure
                                    layout={PageLayout.WHITE}
                                    path='/notifications'
                                    fullHeight
                                    component={withDrupalContent(NotificationsPageLazy)}
                                />
                                <PageRoute secure={true} layout={PageLayout.NONE} path='/user/concierge'
                                           component={withDrupalContent(withBusinessPreSelection(ConciergeLazy))} />
                                <PageRoute layout={PageLayout.NONE} path='/concierge'
                                           component={withDrupalContent(ConciergeLazy)} />
                            </Navigation>
                        }
                    </Navigation>
                }
            </Suspense>
        );
    }
}

GlobalNavigation.defaultProps = {
    externalPaths: [
        '/transactions/liquor-licences-nsw',
        '/transactions/development-consent',
        '/transactions/electrical-individual-contractor-licence',
        '/work-health-and-safety',
        '/crsb/transactions',
        '/crsb/food',
        '/transactions/music',
        '/sfb',
        '/adl',
        '/jobs-plus-program',
        '/business-flood-grant-checker',
    ],
};

GlobalNavigation.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    externalPaths: PropTypes.array,
};

export default GlobalNavigation;
