import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const CaretUpSvg = ({className, color, theme}) => (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
        <path fill={theme.colors[color] || color}
              d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'></path>
    </svg>
);

const CaretUp = styled(withTheme(CaretUpSvg))`
  ${color};
  ${layout};
  ${space};
`;

CaretUp.defaultProps = {
    color: 'darkGrey',
    height: 24,
    width: 24,
};

export default CaretUp;
