import React from 'react';
import {color, layout} from 'styled-system';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const BinSvg = ({className, color, theme}) =>
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 17'>
        <title>Group 6</title>
        <g stroke='none' strokeWidth='1' fill={theme.colors[color] || theme.colors.red}>
            <g transform='translate(-647.000000, -218.000000)'
               fillRule='nonzero'>
                <g transform='translate(31.000000, 199.000000)'>
                    <g transform='translate(614.000000, 10.000000)'>
                        <g transform='translate(2.000000, 9.000000)'>
                            <path
                                d='M10.7332047,2.94232721 L10.3311674,14.9625703 C10.3242673,15.1688735 10.160187,15.3436062 9.97308814,15.3436062 L3.02691186,15.3436062 C2.83999803,15.3436062 2.67571107,15.1682261 2.66883256,14.9625703 L2.26679527,2.94232721 C2.25570253,2.61067337 2.00391455,2.35177252 1.70441104,2.36405599 C1.40490755,2.37633948 1.17110441,2.65515577 1.18219713,2.98680964 L1.58423442,15.0070527 C1.61271624,15.8586114 2.25611507,16.5454545 3.02691186,16.5454545 L9.97308814,16.5454545 C10.7434858,16.5454545 11.3872405,15.8599064 11.4157656,15.0070527 L11.8178029,2.98680964 C11.8288956,2.65515577 11.5950925,2.37633948 11.295589,2.36405599 C10.9960854,2.35177252 10.7442975,2.61067337 10.7332047,2.94232721 Z'
                            ></path>
                            <path
                                d='M4.31384472,5.23951335 L4.55020675,13.6988359 C4.55788221,13.973538 4.77565866,14.1896783 5.03662411,14.1815989 C5.29758955,14.1735194 5.50292164,13.9442796 5.49524618,13.6695776 L5.25888416,5.210255 C5.2512087,4.9355529 5.03343225,4.71941258 4.7724668,4.72749206 C4.51150136,4.73557153 4.30616927,4.96481126 4.31384472,5.23951335 Z'
                            ></path>
                            <path
                                d='M7.85929766,5.210255 L7.62293563,13.6695776 C7.61526018,13.9442796 7.82059227,14.1735194 8.08155771,14.1815989 C8.34252316,14.1896783 8.56029961,13.973538 8.56797507,13.6988359 L8.80433709,5.23951335 C8.81201255,4.96481126 8.60668046,4.73557153 8.34571501,4.72749206 C8.08474957,4.71941258 7.86697311,4.9355529 7.85929766,5.210255 Z'
                            ></path>
                            <path
                                d='M0.590909091,3.54545455 L12.4090909,3.54545455 C12.735441,3.54545455 13,3.28089554 13,2.95454545 C13,2.62819537 12.735441,2.36363636 12.4090909,2.36363636 L0.590909091,2.36363636 C0.264559005,2.36363636 0,2.62819537 0,2.95454545 C0,3.28089554 0.264559005,3.54545455 0.590909091,3.54545455 Z'
                            ></path>
                            <path
                                d='M4.75336201,2.36363636 L4.98951805,1.51823335 C5.0400981,1.3371644 5.26958096,1.16014632 5.45381552,1.16014632 L7.49935779,1.16014632 C7.6837099,1.16014632 7.91303283,1.3370127 7.96365525,1.51823335 L8.19981129,2.36363636 L9.45454545,2.36363636 L9.0542821,1.19951665 C8.8645127,0.520170863 8.19006506,0 7.49935779,0 L5.45381552,0 C4.76319018,0 4.08860843,0.520357646 3.8988912,1.19951665 L3.54545455,2.36363636 L4.75336201,2.36363636 Z'
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>;

const Bin = styled(BinSvg)`
  ${color}
  ${layout}
`;

Bin.defaultProps = {
    height: 20,
    width: 20,
};

export default withTheme(Bin);