import React from 'react';
import PropTypes from 'prop-types';
import Minus from '../Image/minus.svg';
import Plus from '../Image/plus.svg';
import {ClickableImg} from '@snsw/react-component-library';

const ExpandIcon = ({onClick, expanded, className}) =>
    <ClickableImg onClick={onClick}
                  src={expanded ? Minus : Plus}
                  alt='expand/collapse'
                  title={expanded ? 'Show less' : 'Show more'}
                  className={className}/>;

ExpandIcon.propTypes = {
    onClick: PropTypes.func,
    expanded: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

export default ExpandIcon;