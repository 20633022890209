import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const AccessTimeSvg = ({className, color, theme, width, height}) => (
    <svg className={className} width={width} height={height} viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
        <title>E96E0B55-EA2A-4CC5-95A0-36118BAD2D1E@1.00x</title>
        <g id='BC-Form---Iteration-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='2.0:-LG---Iteration-1---Success' transform='translate(-74.000000, -888.000000)'>
                <g id='access_time-24px' transform='translate(74.000000, 888.000000)'>
                    <polygon id='Path' points='0 0 32 0 32 32 0 32'></polygon>
                    <path
                        d='M15.9866667,2.66666667 C8.62666667,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.62666667,29.3333333 15.9866667,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 15.9866667,2.66666667 Z M16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C21.8933333,5.33333333 26.6666667,10.1066667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 Z M16.6666667,9.33333333 L14.6666667,9.33333333 L14.6666667,17.3333333 L21.6666667,21.5333333 L22.6666667,19.8933333 L16.6666667,16.3333333 L16.6666667,9.33333333 Z'
                        id='Shape' fill={theme.colors[color] || color} fillRule='nonzero'></path>
                </g>
            </g>
        </g>
    </svg>
);

const AccessTime = styled(withTheme(AccessTimeSvg))`
  ${color};
  ${layout};
  ${space};
`;

AccessTime.defaultProps = {
    color: '#E2173D',
    height: 32,
    width: 32,
    marginRight: '0.5rem'
};

export default AccessTime;