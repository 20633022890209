import styled from 'styled-components/macro';
import { Notification, Button } from '@snsw/react-component-library';
import {Link} from 'react-router-dom';

export const StyledNotification = styled(Notification)`
    margin-bottom: 5rem;
`;

export const StyledLink = styled(Link)`
    color: #2E5299 !important;
`;

export const HomeButton = styled(Button)`
    color: #242934;
    font-size: 0.875rem;
    font-weight: 300;
    margin-top: 1rem;
    transition: none;

    svg {
        margin-right: 5px;
        vertical-align: middle;
        height: 0.5rem;
        width: 0.5rem;

        path {
            fill: #242934;
        }
    };

    &:focus {
        border-radius: 0.5rem;
    }

    &:active {
        color: #242934;
        background-color: transparent;
        border: none;
        outline: none;
    };

    &:hover:not(:disabled) {
        color: #242934;
        background-color: unset;

        svg {
            path {
              fill: #242934
            };
        };
    };
`;

export const StyledButton = styled(Button)`
    color: #2E5299 !important;
    font-weight: 300;
    
    &:focus {
        outline: none;
    }
    
    &:active {
        color: #242934;
        background-color: transparent;
        border: none;
        outline: none;
    };
    
    &:hover:not(:disabled) {
        color: #242934;
        background-color: unset;

        svg {
            path {
              fill: #242934
            };
        };
    };
`;