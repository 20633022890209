import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const PencilSvg = ({className, color, theme}) => (
    <svg className={className}
         xmlns='http://www.w3.org/2000/svg'
         viewBox='0 0 13 13'>
        <path fill={theme.colors[color] || color}
              fillRule='evenodd'
              d='M517.724288,173.182375 L519.309607,174.09738 L514.248782,182.435855 L512.808338,183.055697 L512.655903,181.720144 L517.724288,173.182375 Z M518.913277,171.175266 L520.513961,172.112468 L519.797397,173.285564 L518.212079,172.363238 L518.913277,171.175266 Z M518.70743,170.053646 C518.545239,170.064744 518.399634,170.153293 518.318905,170.289777 L511.733736,181.387908 C511.679591,181.474332 511.655445,181.575159 511.664957,181.675751 L511.908852,183.800925 C511.939583,184.05996 512.181527,184.246031 512.449324,184.216279 C512.499566,184.210611 512.548589,184.197388 512.594929,184.177317 L514.789986,183.232795 C514.882422,183.192417 514.959737,183.125592 515.010955,183.040821 L521.596124,172.17882 C521.73051,171.956385 521.655878,171.670668 521.428568,171.536782 L518.989372,170.119999 C518.904741,170.070648 518.806451,170.047743 518.70743,170.053646 L518.70743,170.053646 Z'
              transform='rotate(15 907.302 -1844.781)'/>
    </svg>

);

const Pencil = styled(withTheme(PencilSvg))`
  ${color};
  ${layout};
  ${space};
`;

Pencil.defaultProps = {
    color: '#C60C30',
    height: 13,
    width: 13,
};

export default Pencil;