import {api} from 'common';

const transactionApi = {
    initialize: (transactionType) =>
        api.postSecure(`${transactionType.apiPath}/initialize`),

    process: (transactionType, stateId, data) =>
        api.postSecure(`${transactionType.apiPath}/process/${stateId}`, data),

    saveListItem: (transactionType, stateId, field, index, data) =>
        api.postSecure(`${transactionType.apiPath}/data/${stateId}/${field}/${index}`, data),

    saveExemptionCode: (transactionType, data) =>
        api.postSecure(`${transactionType.apiPath}/exemption`, data),

    remove: (transactionType, stateId, field, index) =>
        api.deleteSecure(`${transactionType.apiPath}/data/${stateId}/${field}/${index}`),

    preFill: (transactionType, stateId) =>
        api.getSecure(`${transactionType.apiPath}/prefill/${stateId}`),

    pricing: (transactionType) =>
        api.getSecure(`${transactionType.apiPath}/pricing`),

    summary: (transactionType) =>
        api.getSecure(`${transactionType.apiPath}/summary`),

    finalize: (transactionType) =>
        api.postSecure(`${transactionType.apiPath}/finalize`),

    cancel: (transactionType) =>
        api.putSecure(`${transactionType.apiPath}/cancel`),

    getApplicationStatus: (transactionType) =>
        api.getSecure(`${transactionType.apiPath}/application-status`),

    getLicenceDetails: (transactionType, data) =>
        api.postSecure(`${transactionType.apiPath}/licenceDetails`, data)
};

export default transactionApi;
