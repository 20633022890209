import styled from 'styled-components/macro';
import {EtdbButton as Button} from '@snsw/react-component-library';

const Actions = styled.div`
  margin-top: 2rem;
  ${Button} + ${Button} {
    margin-left: 0.375rem;
  }
`;
export default Actions;
