import {api, getCookie} from 'common';

const businessSuggestionWrapper = (queryString, path) => api.getSecure( path + `/business?queryString=${encodeURIComponent(queryString)}`)
    .catch(_ => {
        throw Error('Business information cannot be fetched');
    });

const userApi = ({
    saveBusinessProfileDetails: (user) => {
        const body = {
            name: user.name,
            abn: user.abn,
            phone: user.phone,
            firstName: user.firstName,
            middleName: user.middleName,
            lastName: user.lastName
        };
        return api.putSecure('/user/business_profile', body);
    },
    saveContactDetails: (mobilePhone) => {
        return api.putSecure( '/user/profile/contact-details', {mobilePhone})
            .catch(_ => {
                throw Error('Unable to save contact details');
            });
    },
    saveBusinessContactDetails: (body) => {
        return api.putSecure( '/user/profile/contact-details', body)
            .catch(_ => {
                throw Error('Unable to save business contact details');
            });
    },
    saveBusinessDetails: (businessDetails) => {
        const body = {
            abn: businessDetails.abn,
            entityName: businessDetails.entityName,
            preferredBusinessName: businessDetails.preferredBusinessName,
            businessName: businessDetails.businessName,
            businessId: businessDetails.businessId
        };
        return api.putSecure('/user/profile/business-details', body)
            .catch(_ => {
                throw Error('Unable to save business details');
            });
    },
    saveBusinessAddress: (addressDetails) => {
        const body = {
            addressDetails: {
                businessAddress: {
                    ...addressDetails.addressDetails.businessAddress,
                    suburb: addressDetails.addressDetails.businessAddress.suburb || '',
                },
                postalAddress: {
                    ...addressDetails.addressDetails.postalAddress,
                    suburb: addressDetails.addressDetails.postalAddress.suburb || '',
                },
            },
            businessId: addressDetails.businessId,
        };

        return api.putSecure('/user/profile/business-address', body)
            .catch(_ => {
                throw Error('Unable to save business address');
            });
    },
    saveRoleDetails: (body) => {
        return api.putSecure( '/user/profile/role-details', body)
            .catch(_ => {
                throw Error('Unable to save role details');
            });
    },
    getBusinessDetails: () => {
        return api.getSecure( '/user/profile')
            .catch(_ => {
                throw Error('Unauthorised');
            });
    },
    createUserProfile: async (isBusinessTransaction) => {
        try {
            return await api.postSecure('/user/profile', {isBusinessTransaction});
        } catch (e) {
            throw Error('Unauthorised');
        }
    },
    getUserProfile: async () => {
        if (getCookie('CSRF') === undefined) {
            throw Error('Unauthorised');
        }
        try {
            return await api.getSecure('/user');
        } catch (e) {
            throw Error('Unauthorised');
        }
    },
    get: (uri, error = 'Error when fetching data') => {
        return api.getSecure( `/${uri}`).catch(_ => {
            throw Error(error);
        });
    },
    getBusinessInfoByAbn: (abn) => {
        return api.getSecure( `/user/abn/${abn}`)
            .catch(_ => {
                throw Error('ABN cannot be fetched');
            });
    },
    getBusinessSuggestions: (queryString) => businessSuggestionWrapper(queryString, '/user'),
    getConciergeBusinessSuggestions: (queryString) => businessSuggestionWrapper(queryString, '/api/crsb'),
    getBusinessOverviewByUserBusinessId: (userBusinessId) => {
        return userApi.get(`user/business/${userBusinessId}/overview`, 'Business information cannot be fetched');
    },
    removeBusiness: async ({businessId, removalReason}) => {
        try {
           return await api.deleteSecure(`/user/profile/business/${businessId}`, {removalReason});
        } catch (e) {
            throw Error('Unable to remove business');
        };
    },
    getBusinessServices: (businessId) => {
        return userApi.get(`user/business/${businessId}/businessInteractions?interactionType=SERVICE`, 'Unable to retrieve user business services');
    },
    createBusinessDetails: (businessDetails) => {
        const body = {
            abn: businessDetails.abn,
            entityName: businessDetails.entityName,
            preferredBusinessName: businessDetails.preferredBusinessName,
            businessName: businessDetails.businessName,
        };
        return api.postSecure('/user/profile/business-details', body).catch(_ => {
            throw Error('Unable to save business details');
        });
    },
});

export default userApi;

