import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BusinessPickerContainer, DropdownContainer, Separator} from './BusinessPicker.styled';
import {IconChevronDown, IconChevronUp, ReactDropdown} from 'common';

const addNewBusinessOption = {value: 'Add new business', className: 'add-new-business'};
export const BusinessPicker = ({user, history, location, selectedBusinessId, businesses, updateSelectedBusinessId}) => {
    const [options, setOptions] = useState([]);

    const isDisplayed = (businesses, location) => {
        return businesses.length > 0
            && !location.pathname.includes('/add-business')
            && !location.pathname.includes('/notifications');
    };

    useEffect(() => {

        const options = businesses.map(business => ({
            value: business.id,
            label: business.name,
        }));
        options.unshift(addNewBusinessOption);
        setOptions(options);
    }, [businesses]);

    const onBusinessProfileChange = selected => {
        if (selected.value === addNewBusinessOption.value) {
            history.push('/add-business#business-details');
        } else {
            updateSelectedBusinessId(selected.value);
            if (!location.pathname.includes('/dashboard')) {
                history.push('/dashboard');
            }
        }
    };
    const getBusinessName = () => {
        const option = options.find(option => option.value === selectedBusinessId);
        const label = option.label && option.label.length > 50 ? option.label.substring(0, 50) + '...' : option.label;
        return {value: option.value, label};
    };

    return (isDisplayed(businesses, location) && <BusinessPickerContainer>
        <DropdownContainer data-testid='business-picker'>
            <label htmlFor='business-picker'>You are viewing</label>
            <ReactDropdown options={options} onChange={onBusinessProfileChange}
                           value={selectedBusinessId && options.length ? getBusinessName() : addNewBusinessOption}
                           arrowClosed={<IconChevronDown/>}
                           arrowOpen={<IconChevronUp/>}
                           placeholder='Select a business'
                           className='business-picker-step'
            />
        </DropdownContainer>
        <Separator/>
    </BusinessPickerContainer>);
};

const mapDispatchToProps = (dispatch) => ({
        updateSelectedBusinessId: (selectedBusinessId) => {
            dispatch({type: 'SELECTED_BUSINESS_ID_UPDATED', payload: {selectedBusinessId}});
        },
    }
);

export default connect(null, mapDispatchToProps)(BusinessPicker);

BusinessPicker.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    selectedBusinessId: PropTypes.string.isRequired,
    businesses: PropTypes.array.isRequired,
    updateSelectedBusinessId: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};