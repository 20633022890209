import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import * as ReactGA from 'react-ga';
import App from './app/App';
import {GlobalStyle, getCookie} from 'common';
import ReactGA4 from 'react-ga4';
import env from '@beam-australia/react-env';

window.fetchIB = window.fetch;
window.fetch = (url, options) => {
    const token = getCookie('CSRF');
    if (token !== undefined && options && options.method && options.method.toUpperCase() !== 'GET') {

        if (!options.headers || typeof options.headers === 'undefined') {
            options.headers = {};
        }
        options.headers['X-CSRF-Token'] = token;
    }
    // if (options && options.credentials) {
    //     delete options.credentials;
    // }
    return window.fetchIB(url, options);
};
window.localStorage.setItem('LicenceStreamFeature',true);

try{
    ReactGA.initialize('UA-123266782-2');
} catch (err) {
    console.error('GA3 error: ', err);
}
env('ENABLE_GA4') === 'true' && ReactGA4.initialize(env('GA4_MEASUREMENT_ID'));

const AppWrapper = () => <><GlobalStyle/><App/></>;

ReactDOM.render(
    <AppWrapper/>,
    document.getElementById('root')
);