import React, {useEffect} from 'react';
import env from '@beam-australia/react-env';

const sloUrlsMap = {
    development: 'https://myservicensw-account-ui.staging.my.testservicensw.net/api/auth/logout',
    ci: 'https://myservicensw-account-ui.it1.my.testservicensw.net/api/auth/logout',
    sit: 'https://myservicensw-account-ui.it3.my.testservicensw.net/api/auth/logout',
    it4: 'https://myservicensw-account-ui.it4.my.testservicensw.net/api/auth/logout',
    acceptance: 'https://myservicensw-account-ui.staging.my.testservicensw.net/api/auth/logout',
    preprod: 'https://myservicensw-account-ui.load.my.testservicensw.net/api/auth/logout',
    production: 'https://account.service.nsw.gov.au/api/auth/logout'
};

const getSloUrl = () => {
    const currentEnv = env('NODE_ENV');
    return sloUrlsMap[currentEnv || 'acceptance'];
};

export const redirectToSloUrl = () => {
    window.location.replace(getSloUrl());
};

export const SLORedirect = () => {
    useEffect(() => {
        redirectToSloUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};
export default SLORedirect;
