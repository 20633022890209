import React from 'react';
import PropTypes from 'prop-types';

const Plus = ({color = '#2e5299', height = '12', width = '12'}) =>
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 12 12'>
        <path fill={color} fillRule='evenodd' d='M5.08 12V6.893H0V5.107h5.08V0h1.84v5.107H12v1.786H6.92V12z'/>
    </svg>;

Plus.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default Plus;
