import {combineReducers} from 'redux';
import userReducer from '../components/user/userReducer';
import layoutReducer from '../components/layout/layoutReducer';
import businessAdvisorReducer from '../transactions/businessConnect/advisors/businessAdvisorReducer';
import businessConnectFilterReducer from '../transactions/businessConnect/businessConnectFilterReducer';
import businessEventReducer from '../transactions/businessConnect/events/businessEventReducer';
import {transactionReducer} from 'transaction';
import dashboardReducer from '../components/dashboard/dashboardReducer';
import licenceManagementReducer from '../components/account/LicenceManagement/licenceManagementReducer';
import tooltipReducer from '../tooltip/tooltipReducer';
import navMenuReducer from '../dashboard/navMenu/navMenuReducer';

export default combineReducers({
    user: userReducer,
    tooltip: tooltipReducer,
    navMenu: navMenuReducer,
    layout: layoutReducer,
    businessConnectAdvisor: businessAdvisorReducer,
    businessConnectFilter: businessConnectFilterReducer,
    businessConnectEvent: businessEventReducer,
    transaction: transactionReducer,
    dashboard: dashboardReducer,
    licenceManager: licenceManagementReducer
});
