import navMenuStore from './navMenuStore';

export const UPDATE_NAV_MENU_REQUEST = 'UPDATE_NAV_MENU_REQUEST';

export default (state = navMenuStore, action) => {
    switch (action.type) {
        case UPDATE_NAV_MENU_REQUEST : {
            return {
                ...state,
                open: action.payload.open
            };
        }
        default: {
            return state;
        }
    }
};