import userStore from '../login/userStore';
import {SHOW_ERROR} from '../layout/layoutReducer';
import {LOCALSTORAGE_KEY_PREFIX} from '../login/userActions';

export const USER_INITIALISED = 'USER_INITIALISED';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const USER_UPDATING = 'USER_UPDATING';
export const SELECTED_BUSINESS_ID_UPDATED = 'SELECTED_BUSINESS_ID_UPDATED';
export const UPDATE_USER_BUSINESSES = 'UPDATE_USER_BUSINESSES';

export default (state = userStore, action) => {
    const {type, payload} = action;
    switch (type) {
        case USER_UPDATING: {
            return {
                ...state,
                updating: true,
            };
        }
        case SHOW_ERROR: {
            return {
                ...state,
                updating: false,
            };
        }
        case UPDATE_USER_PROFILE: {
            return {
                ...state,
                ...payload,
                initialised: true,
            };
        }
        case USER_INITIALISED: {
            return {
                ...state,
                initialised: true,
            };
        }
        case SELECTED_BUSINESS_ID_UPDATED: {
            const {snswCustomerId, businesses} = state;
            const selectedBusiness = businesses.find(business => business.id === payload.selectedBusinessId);

            // update local store of new selected business
            localStorage.setItem(LOCALSTORAGE_KEY_PREFIX + snswCustomerId, JSON.stringify({selectedBusinessId: payload.selectedBusinessId}));

            return {
                ...state,
                selectedBusinessId: payload.selectedBusinessId,
                selectedBusiness,
                updating: false,
            };
        }
        case UPDATE_USER_BUSINESSES: {
            const {businesses, selectedBusinessId} = payload;
            const selectedBusiness = businesses.find(business => business.id === payload.selectedBusinessId);
            return {
                ...state,
                businesses,
                selectedBusiness,
                selectedBusinessId,
                updating: false,
            };
        }
        default:
            return state;
    }
};
