import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {NotificationCountBadge, NotificationIconWrapper, NotificationLinkContainer, NotificationText} from './NotificationLink.styled';

export const NotificationLink = ({unread}) => {
    const count = unread > 9 ? '9+': unread;
    const history = useHistory();
    return (
        <NotificationLinkContainer className='notifications-tooltip' data-testid='notification-link' href='/notifications' onClick={(e) => {
            e.preventDefault();
            history.push('/notifications');
        }}>
            <NotificationIconWrapper>
                {
                    !!unread &&
                    <NotificationCountBadge
                        data-testid='notification-count-badge'>
                        <span>{count}</span>
                    </NotificationCountBadge>
                }
                <svg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg'>
                    <title>Group 3</title>
                    <g id='Phase-3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                        <g id='Group-3' transform='translate(1.000000, 1.000000)' fill='#000000' fillRule='nonzero'>
                            <g id='Group-2'>
                                <path d='M9,20 C9,21.6568542 10.3431458,23 12,23 C13.5976809,23 14.9036609,21.75108 14.9949073,20.1762728 L15,20 L17,20 C17,22.7614237 14.7614237,25 12,25 C9.3112453,25 7.11818189,22.8776933 7.00461951,20.2168896 L7,20 L9,20 Z M20,17 L20,9 C20,4.581722 16.418278,1 12,1 C7.66508574,1 4.13545713,4.44783777 4.00380682,8.75082067 L4,9 L4,17 L20,17 Z M2,9 C2,3.4771525 6.4771525,-1 12,-1 C17.4292399,-1 21.8479317,3.32667079 21.9961582,8.72009516 L22,9 L22,17 L24,17 C24.5522847,17 25,17.4477153 25,18 C25,18.5128358 24.6139598,18.9355072 24.1166211,18.9932723 L24,19 L0,19 C-0.55228475,19 -1,18.5522847 -1,18 C-1,17.4871642 -0.61395981,17.0644928 -0.116621125,17.0067277 L0,17 L2,17 L2,9 Z' id='Combined-Shape'/>
                            </g>
                        </g>
                    </g>
                </svg>
            </NotificationIconWrapper>
            <NotificationText>Notifications</NotificationText>
        </NotificationLinkContainer>
    );
};

NotificationLink.propTypes = {
    unread: PropTypes.number.isRequired,
};

export const mapStateToProps = ({dashboard}) => ({
    unread: dashboard.unreadNotificationsCount,
});
export default connect(mapStateToProps)(NotificationLink);
