import React from 'react';
import PropTypes from 'prop-types';

const NoLayout = ({children}) => <>{children}</>;

NoLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default NoLayout;
