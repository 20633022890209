import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ComponentLoader, loginHandler} from 'common';
import {connect} from 'react-redux';
import userApi from '../user/userApi';
import Page from './Page';
import userActions from '../login/userActions';
import {Redirect} from 'react-router';

export const ProtectedPage = (pageProps) => {
    const [showSpinner, setShowSpinner] = useState(true);
    const {updateUserProfile, location, onboarded, user, history} = pageProps;

    const isRecursiveCall = () => {
        const callData = JSON.parse(localStorage.getItem('callData') || null);
        if (!callData) {
            localStorage.setItem('callData', JSON.stringify({
                firstCallTime: Date.now(),
                number: 1
            }));
            return false;
        }
        const {firstCallTime, number} = callData;
        const timeElapsedInSeconds = Math.floor((Date.now() - firstCallTime) / 1000);
        if (number > 2 && timeElapsedInSeconds >= 10) {
            localStorage.removeItem('callData');
            return true;
        } else {
            localStorage.setItem('callData', JSON.stringify({
                firstCallTime: callData.firstCallTime,
                number: callData.number + 1,
            }));
            return false;
        }
    };

    useEffect(() => {
        userApi.getUserProfile().then((response) => {
            updateUserProfile(response);
            localStorage.removeItem('callData');
            setShowSpinner(false);
        }).catch(() => {
            if (isRecursiveCall()) {
                history.push('/service-unavailable');
                return;
            }
            const {pathname, search, hash} = location;
            const query = search ? search : hash;
            const savedPath = `${pathname}${query}`;
            loginHandler.redirectToLoginPage(savedPath);
        });
    }, [location, updateUserProfile, history]);

    if (showSpinner) {
        return <ComponentLoader fullPage label='Redirecting you to MyServiceNSW Account...'/>;
    }

    if (onboarded && user.businesses.length === 0) {
        return <Redirect to='/dashboard'/>;
    }

    return <Page {...pageProps}/>;
};

ProtectedPage.propTypes = {
    location: PropTypes.object.isRequired,
    onboarded: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    updateUserProfile: PropTypes.func,
    ...Page.propTypes,
};

ProtectedPage.defaultProps = {
    onboarded: false,
};

const mapStateToProps = ({user}) => ({
    user,
});

export default connect(mapStateToProps, userActions)(ProtectedPage);