import styled from 'styled-components/macro';
import Content from '../components/Content';
import {media, th} from '@snsw/react-component-library';
import {StyledH1, StyledH4, StyledH5} from '../../../Heading/Heading';
import PageTitle from '../components/PageTitle';
import Actions from '../components/Actions';
import FullWidthContent from '../components/FullWidthContent';
import SideBar from '../components/SideBar';
import {Flex, Progress, SupportCopy} from '../../..';
import PreFooter from '../components/PreFooter';

export const GelBizRelationshipProofingLayoutStyled = styled.main`
  background-color: ${props => props.theme.colors.white};
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc((100vw - 76.5rem)/2) calc(76.5rem * 0.7) calc(76.5rem * 0.3) calc((100vw - 76.5rem)/2);
  grid-template-columns: auto minmax(20rem, calc(76.5rem * 0.6)) minmax(20rem, calc(76.5rem * 0.4)) auto;
  grid-template-areas:
    ".               page-title             page-title                  ."
    ".               progress               progress                    ."
    ".               content                side-bar                    ."
    ".               full-width-content     full-width-content          ."
    ".               actions                .                           ."
    ".               pre-footer             pre-footer                  .";
    
  @media (max-width: ${media.lg}px) {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    grid-template-areas:      
      "page-title" 
      "progress"
      "content"
      "side-bar"
      "full-width-content"
      "actions"
      "pre-footer";
  }
  
  ::before, ::after {
    content: '';
    background-color: ${props => props.theme.colors.white};
  }

  ${PageTitle} {
    grid-area: page-title;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    
    @media (max-width: ${media.md}px) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
     }
     
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    margin-top: ${th[1.5]};
    margin-bottom: ${th[1.5]};
    
  }
  
  ${Progress} {
     grid-area: progress;
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2; 
    
     width: 100%;
     
    .verify-business-loader {
        position: unset !important;
        padding-top: 5rem;
        padding-bottom: 20rem;
        font-weight: bold;
        font-size: 1.5rem;
    }
    
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
    
    .verify-business-loader {
        padding-bottom: 10rem;
        font-size: 1.25rem;
    }
 }
}
  
  
  ${Content} {
    grid-area: content;
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; 
    
     width: 100%;
    
     @media (max-width: ${media.md}px) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        
     ${Flex}{
            flex-direction:column
        }
     }
     
     > * {
            padding-bottom: 0.5rem;
        }

    margin-top: ${th[1]};
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    
    @media (max-width: ${media.md}px) {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
  }
  
    
  ${SideBar}
  grid-area: side-bar;
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 4;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
  }
  
   ${FullWidthContent} {
    grid-area: full-width-content;
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2; 
    max-width: 45rem;
    
    .biz-profile-widget {
        max-width : 35rem;
        padding-bottom: 1.25rem;
    }
       
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    
    > ${StyledH1},${StyledH5},${StyledH4}{
        padding-bottom: 0.5rem;
    }
    
    .notice {
        margin-top : 2.5rem;
        margin-bottom : 2.5rem;
        padding-bottom : 1rem;
        max-width: 35rem;
    }
            
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 5;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      margin-top: 0;
      
      ${SupportCopy} > button, a {
        width: unset;
    }
    
    .verify-business-loader {
        padding-bottom: 10rem;
        font-size: 1.25rem;
    }

      ${Flex} {
        flex-direction:column
      }
      
      .notice >  * {
        flex-flow: unset;
      }
      
      .notice {
        margin-top : 1rem;
      }
    }
  }
  
  ${Actions} {
    grid-area: actions;
    -ms-grid-row: 5;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; 
    
    margin-bottom: ${th[3]};
    
    width: 100%;
    max-width: 42rem;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    padding-left: 1.875rem;
    padding-right: 1.875rem;
        
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 6;
      -ms-grid-column: 1;
     padding-left: 1.25rem;
     padding-right: 1.25rem;
    }
  }
  
  ${PreFooter} {
    grid-area: pre-footer;
    -ms-grid-row: 6;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2; 
    
     @media (max-width: ${media.md}px) {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    
    display: flex;
    flex-direction: column;
    
    margin-bottom: ${th[1]};
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
`;