import React from 'react';
import styled from 'styled-components/macro';
import Footer from './Footer';

const SecondaryFooterContainer = (props) => <Footer {...props} level='secondary' />;

const SecondaryFooter = styled(SecondaryFooterContainer)`

`;

export default SecondaryFooter;