import React from 'react';
import ContentLayout from '../../ContentLayout';
import {MySNSWLandingPageLayoutContainer} from './MySNSWLandingPageLayout.styled';

const MySNSWLandingPageLayout = ({children}) => {
    return (
        <>
            <MySNSWLandingPageLayoutContainer aria-label='Main content'>
                {children}
            </MySNSWLandingPageLayoutContainer>
        </>
    );
};

MySNSWLandingPageLayout.propTypes = {
    ...ContentLayout.propTypes,
};

MySNSWLandingPageLayout.defaultProps = {
    ...ContentLayout.defaultProps,
};

export default MySNSWLandingPageLayout;
