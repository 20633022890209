import env from '@beam-australia/react-env';
import globalNavHandler from './global-nav';

const EXTERNAL_RESOURCES = [
    {
        scriptName: 'Global Navigation',
        scriptId: 'global-nav-embed',
        scriptPath: env('NODE_ENV') === 'production' ?
            'https://www.service.nsw.gov.au/assets/global-nav-embed-api.js?apikey=T1ZX8qeetAnFng5K9nltswdekuEkVvVv' :
            'https://' + (env('NODE_ENV') === 'development' ? 'dev' : 'stg') + '.snsw-d8.snsw.skpr.dev/assets/global-nav-embed-api.js?apikey=G2JZdEiwYj5EqKjbZz0mhyGkOnP27MYL',
        isLoaded: () => document.getElementById('block-global-header-account-menu'),
        onScriptLoad: globalNavHandler,
    },
    env('WEB_CHAT_FLAG') === 'true' && {
        scriptName: 'Web Chat',
        scriptId: 'genesys-cx-widget-script',
        scriptPath: 'https://apps.mypurecloud.com.au/widgets/9.0.015/widgets.min.js',
        isLoaded: () => document.getElementById('genesys-cx-widget-script'),
    },
];

const scriptHandler = ({
                           scriptId,
                           scriptPath,
                           isLoaded = () => null,
                           onScriptLoad = () => null,
                           repeatCount = 20,
                           repeatDelay = 250,
                       }, email) => {
    if (isLoaded()) {
        // If already exists, do not load again
        return;
    }
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.src = scriptPath;
    script.async = true;
    document.body.appendChild(script);
    let loopTimes = 0;
    const handleLoad = setInterval(() => {
        if (isLoaded()) {
            clearInterval(handleLoad);
            onScriptLoad(email);
        }

        loopTimes++;
        if (loopTimes >= repeatCount) {
            clearInterval(handleLoad);
        }
    }, repeatDelay);
};

export default (email) => EXTERNAL_RESOURCES.forEach(a => scriptHandler(a, email));
export {removeGlobalNav} from './global-nav';
export const loadWebChat = () => EXTERNAL_RESOURCES.forEach(a => a.scriptName === 'Web Chat' && scriptHandler(a));
