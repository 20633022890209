import React from 'react';
import PropTypes from 'prop-types';
import PaddedLayout from './PaddedLayout';

const WhiteLayout = ({children}) =>
    <PaddedLayout backgroundColor='#ffffff'>
        {children}
    </PaddedLayout>;

WhiteLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default WhiteLayout;
