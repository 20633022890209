import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const BarsSvg = ({className, color, theme}) => (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
        <path fill={theme.colors[color] || color}
              d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'></path>
    </svg>
);

const Bars = styled(withTheme(BarsSvg))`
  ${color};
  ${layout};
  ${space};
`;

Bars.defaultProps = {
    color: 'darkGrey',
    height: 24,
    width: 24,
};

export default Bars;
