export const BUSINESS_CONNECT_FILTER_SUCCESS = 'BUSINESS_CONNECT_FILTER_SUCCESS';
export const BUSINESS_CONNECT_FILTER_FAILURE = 'BUSINESS_CONNECT_FILTER_FAILURE';
export const BUSINESS_CONNECT_FILTER_REQUEST = 'BUSINESS_CONNECT_FILTER_REQUEST';
export const BUSINESS_CONNECT_SET_FILTER_INPUT_VALUE = 'BUSINESS_CONNECT_SET_FILTER_INPUT_VALUE';
export const BUSINESS_CONNECT_SET_FILTER = 'BUSINESS_CONNECT_SET_FILTER';
export const BUSINESS_CONNECT_CLEAR_FILTER = 'BUSINESS_CONNECT_CLEAR_FILTER';

export const setInputValue = (inputValue) => ({type: BUSINESS_CONNECT_SET_FILTER_INPUT_VALUE, payload: inputValue});
export const clearFilters = () => ({type: BUSINESS_CONNECT_CLEAR_FILTER});
export const setFilters = (values) => ({type: BUSINESS_CONNECT_SET_FILTER, payload: values});
export const getFilterOptions = (filters) => ({type: BUSINESS_CONNECT_FILTER_REQUEST, payload: filters});
