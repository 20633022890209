import * as ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import env from '@beam-australia/react-env';

export const analyticsUtil = {
    /**
     * Send a GA event with a duration (ms)
     * @param category The name of the event. For example: back-to-school
     * @param action The action users take. For example: Button
     * @param label The label of the UI component. For example: back-to-school|Start application
     * @param duration The duration in ms.
     * @param nonInteraction Interaction event means that the user will not be counted as a bounce
     * @param params A json object containing any custom params (new feature from GA4)
     */
    sendGAEventWithDuration(category, action, label, duration, nonInteraction = false, params = {}) {
        env('ENABLE_GA4') === 'true' && ReactGA4.event(category, {
            action: action,
            label: label,
            value: duration,
            nonInteraction: nonInteraction,
            ...params,
        });
        try {
            ReactGA.event({
                category: category,
                action: action,
                label: label,
                value: duration,
                nonInteraction: nonInteraction,
            });
        } catch (err) {
            console.error('GA3 error: ', err);
        }
    },

    /**
     * Send a GA event
     * @param category The name of the event. For example: back-to-school
     * @param action The action users take. For example: Button
     * @param label The label of the UI component. For example: back-to-school|Start application
     * @param nonInteraction Interaction event means that the user will not be counted as a bounce
     * @param params A json object containing any custom params (new feature from GA4)
     */
    sendGAEvent(category, action, label, nonInteraction = false, params = {}) {
        env('ENABLE_GA4') === 'true' && ReactGA4.event(category, {
            action: action,
            label: label,
            nonInteraction: nonInteraction,
            ...params,
        });
        try {
            ReactGA.event({
                category: category,
                action: action,
                label: label,
                nonInteraction: nonInteraction,
            });
        } catch (err) {
            console.error('GA3 error: ', err);
        }
    },

    sendGAClickEvent(category, eventLabel) {
        this.sendGAEvent(category, 'click', eventLabel);
    },

    sendGAEventWithCustomDimension(category, label, dimensionId, eventDetails, action = 'click') {
        try {
            ReactGA.set({[dimensionId]: eventDetails});
        } catch (err) {
            console.error('GA3 error: ', err);
        }
        this.sendGAEvent(category, action, label);
    },

    /**
     * @deprecated Keep it here because there are legacy events
     * Not recommend use it in GA4 as it's being covered by gtm already
     */
    modalview(name) {
        try {
            ReactGA.modalview(name);
        } catch (err) {
            console.error('GA3 error: ', err);
        }
        env('ENABLE_GA4') === 'true' && ReactGA4.gtag('event', 'view_item', {
            items: [{name: name}],
        });
    },

    /**
     * Push a payload to the GA4 datalayer
     *
     * @param {string} type
     * @param {string} text
     * @param {string | undefined} sectionId
     * @param {string | undefined} category
     * @param {string | undefined} positionId
     * @param {string | undefined} destUrl
     */
    pushComponentAnalytics({type, text, category = undefined, sectionId = undefined, positionId = undefined, destUrl = undefined}) {
        const payload = {
            event: 'component_interaction',
            component_type: type,
            component_category: category ?? '(not set)',
            component_text: text,
            section_id: sectionId ?? '(not set)',
            position_id: positionId ?? '1',
            destination_url: destUrl ?? '(not set)'
        };

        if (env('ENABLE_GA4_DATALAYER') === 'true' && window?.dataLayer) {
            window.dataLayer.push(payload);
        }
    },

    /**
     *
     * @param {string} type
     * @param {string} name
     * @param {string} text
     * @param {string} errorPos
     * @param {string | undefined} positionId
     */
    pushAlertAnalytics({type, name, text, errorPos, positionId = undefined}) {
        const payload = {
            event: 'notice_alert',
            error_type: type,
            error_name: name,
            error_text: text,
            error_position: errorPos,
            position_id: positionId ?? '1'
        };

        if (env('ENABLE_GA4_DATALAYER') === 'true' && window?.dataLayer) {
            window.dataLayer.push(payload);
        }
    }
};