import React from 'react';
import styled from 'styled-components/macro';
import {color, layout, space} from 'styled-system';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const TickCircledSvg = ({className, width, height, color, theme}) => (
    <svg className={className} width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 22'>
        <path fill={theme.colors[color] || color} fillRule='nonzero' d='M22.677.385a1.095 1.095 0 0 0-.787-.337c-.295 0-.578.121-.786.337L18.28 3.297C14.307-.88 7.86-1.118 3.606 2.754-.646 6.627-1.215 13.255 2.312 17.838c3.527 4.583 9.915 5.516 14.538 2.125 4.622-3.392 5.862-9.922 2.82-14.86l3.001-3.096c.21-.214.328-.506.329-.81a1.165 1.165 0 0 0-.323-.812zm-3.563 10.552c0 4.109-2.817 7.644-6.726 8.438-3.909.794-7.816-1.375-9.326-5.177-1.51-3.801-.202-8.174 3.124-10.436 3.325-2.263 7.738-1.783 10.532 1.145l-5.941 6.128L8.78 8.977a1.089 1.089 0 0 0-1.573 0 1.173 1.173 0 0 0 0 1.622l3.569 3.68 7.286-7.515a8.762 8.762 0 0 1 1.051 4.173z'/>
    </svg>
);

const TickCircled = styled(withTheme(TickCircledSvg))`
  ${color}
  ${layout}
  ${space}
`;

TickCircled.defaultProps = {
    color: 'blue',
    height: 22,
    width: 23,
};

export default TickCircled;