import React from 'react';
import ContentLayout from '../../ContentLayout';
import {GelBizRelationshipProofingLayoutStyled} from './GelBizRelationshipProofingLayout.styled';

const GelBizRelationshipProofingLayout = ({children}) => {
    return (
        <>
            <GelBizRelationshipProofingLayoutStyled aria-label='Main content'>
                {children}
            </GelBizRelationshipProofingLayoutStyled>
        </>
    );
};

GelBizRelationshipProofingLayout.propTypes = {
    ...ContentLayout.propTypes,
};

GelBizRelationshipProofingLayout.defaultProps = {
    ...ContentLayout.defaultProps,
};

export default GelBizRelationshipProofingLayout;