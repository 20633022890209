import styled from 'styled-components/macro';
import {colors} from 'common';

export const NotificationLinkContainer = styled.a`
  display: flex;
  position: relative;
  margin-left: 0px;
  margin-top: 9px;

  > span {
    vertical-align: top;

    @media (min-width: 1024px) {
      margin-left: 8px;
    }
  }

  @media (max-width: ${1024 - 1}px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-left: 0;
    }
  }
`;

export const NotificationIconWrapper = styled.div`
  position: relative;
  
  @media (min-width: 1024px) {
    svg {
      height: 24px;
      width: 24px;
    }
  }
  
  @media (max-width: ${1024 - 1}px) {
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const NotificationText = styled.span`
    color: ${colors.blue};
    text-decoration: underline;
    font-weight: 500;
    text-align: center;
`;

export const NotificationCountBadge = styled.div`
  position: absolute;
  top: -20px;
  left: 11px;
  border-radius: 24px;
  height: 24px;
  background-color: #d7153a;
  border: 1px solid rgb(167, 25, 48);
  background: rgb(215, 21, 58);
  min-width: 24px;
  display: flex;
  justify-content: center;
  
  span {
    vertical-align: top;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin: 0 4px;
  }
  
  @media (max-width: ${1024 - 1}px) {
    top: -8px;
    left: 8px;
    height: 16px;
    min-width: 16px;
      
    span {
        font-size: 10px;
    }
  }
`;
