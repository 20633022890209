import styled from 'styled-components/macro';
import {StyledH3,media} from 'common';
import {MaxWidthContainer} from '../HeaderFooter.styled';

export const StyledFeedbackContainer = styled.div`
    background: rgb(243, 245, 248);
    border-radius: 0px;
    
    ${MaxWidthContainer}{
        margin: 2.5rem auto;
    }
      
    ${StyledH3}{
        color: rgb(36, 41, 52);
        font-size: 22px;
        font-weight: bold;
        margin-right:12px;
    }
`;

export const FeedbackForm = styled.div`
    width: 50% !important;
    margin-bottom: 2.5rem;
    @media (max-width: ${media.md}px) {
        width: 100% !important;
    }
`;

export const FeedbackQuestion = styled.div`
    display: flex;
    align-items: center;
    & button {
        margin: 0px 12px;        
    }

   & button:hover:not(:disabled) {
     background-color: unset;
   }
   & button:focus {
     outline: unset;
   }
    
    @media (max-width: ${media.sm}px) {
       display: block;
       & button {
          width:unset !important;
          margin: 0px 0px !important;
          :last-child {
            margin: 0px 24px !important;
         }
       }
       h3 {
         padding-bottom: 0.5rem;
       };
    }
`;
