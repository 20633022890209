import React from 'react';
import {IconChevronLeft} from 'common';
import {HomeButton, StyledButton, StyledLink, StyledNotification} from './NotFound.styled';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

const NotFound = ({user, history}) => {
    const isLoggedIn = user && user.email;
    const notification = (redirectLink) => {
        return (
            <>
                <h1>We're sorry about this...</h1>
                <StyledNotification variant='info' title='Page not found' headingLevel='h4'>
                    <p>The page you’re looking for has been moved or no longer exists.</p>
                    {redirectLink}
                </StyledNotification>
            </>
        );
    };

    return <>
        {isLoggedIn ? <>
                <HomeButton variant='link' onClick={() => history.goBack()}>
                    <IconChevronLeft/>Previous page</HomeButton>
                {notification(<p>Try going back to the previous page or return to your <StyledLink
                    to='/dashboard/overview'>Business Profile account</StyledLink>.
                </p>)}
            </>
            :
            <>
                <HomeButton variant='link' onClick={() => history.push('/')}>
                    <IconChevronLeft/>Home</HomeButton>
                {notification(<p>Search the keyword results above or go back to the <StyledButton
                    variant='link' href='https://www.service.nsw.gov.au/business'>Homepage</StyledButton>
                </p>)}
            </>
        }
    </>;
};

NotFound.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string
    }),
    history: PropTypes.object.isRequired
};

export const mapStateToProps = ({user}) => ({
    user
});

export default connect(mapStateToProps)(withRouter(NotFound));
