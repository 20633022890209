import React from 'react';
import {FooterBarContainer, FooterExternalLink, FullWidthStatusBar, MaxWidthContainer} from './HeaderFooter.styled';
//import {Icons} from 'common';

export const LoggedInFooter = () => {
    return <FullWidthStatusBar>
        <MaxWidthContainer>
            <FooterBarContainer>
                <ul>
                    <li>
                        <a href='https://www.service.nsw.gov.au/accessibility'>Accessibility</a>
                    </li>
                    <li>
                        <a href='https://www.service.nsw.gov.au/privacy'>Privacy</a>
                    </li>
                    <li>
                        <a href='https://www.service.nsw.gov.au/terms-use'>Terms of use</a>
                    </li>
                    <li>
                        <a href='https://www.service.nsw.gov.au/copyright-and-disclaimer'>Copyright and
                            disclaimer</a>
                    </li>
                    <li>
                        <a href='https://www.service.nsw.gov.au/accessing-information'>Accessing information</a>
                    </li>
                    <li>
                        <FooterExternalLink href='https://www.nsw.gov.au'>NSW Government</FooterExternalLink>
                    </li>

                </ul>
            </FooterBarContainer>
        </MaxWidthContainer>
    </FullWidthStatusBar>;
};