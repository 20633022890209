import React from 'react';
import {Heading} from '@snsw/react-component-library';
import styled from 'styled-components/macro';
import {css} from 'styled-components';
import {color, space, typography} from 'styled-system';

export const NewH1 = (props) => <Heading level={1} {...props}>{props.children}</Heading>;
export const NewH2 = (props) => <Heading level={2} {...props}>{props.children}</Heading>;
export const NewH3 = (props) => <Heading level={3} {...props}>{props.children}</Heading>;
export const NewH4 = (props) => <Heading level={4} {...props}>{props.children}</Heading>;
export const NewH5 = (props) => <Heading level={5} {...props}>{props.children}</Heading>;
export const NewH6 = (props) => <Heading level={6} {...props}>{props.children}</Heading>;

const heading = css`
  margin: 0;
  letter-spacing: 0;
  font-weight: 700;
  ${props => props.color && css`
    color: ${props.color};
  `}
`;

const h1Styles = css`
  font-size: 2.25rem;
  line-height: 1.388888;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 1.375;
  }
`;

export const H1WithoutFocusRectangle = styled.h1`
  ${heading};
  ${h1Styles}
  
  :focus {
    outline: none;
  }
`;

export const StyledH1 = styled(NewH1)`
  ${heading};
  ${h1Styles};
`;

export const StyledH2 = styled(NewH2)`
  ${heading};

  font-size: 1.75rem;
  line-height: 1.42857;

  @media (max-width: 768px) {
    font-size: 1.625rem;
    line-height: 1.384615;
  }
`;

export const StyledH3 = styled(NewH3)`
  ${heading};

  font-size: 1.375rem;
  line-height: 1.363636;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  ${space}
  ${color};
  ${typography}
`;

export const StyledH4 = styled(NewH4)`
  ${heading};

  font-size: 1.125rem;
  line-height: 1.444444;
`;

export const StyledH5 = styled(NewH5)`
  ${heading};

  font-size: 1rem;
  line-height: 1.5;
`;

export const StyledH6 = styled(NewH6)`
  ${heading};

  font-size: 1rem;
  line-height: 1.5;
`;