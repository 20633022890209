import React from 'react';
import styled from 'styled-components/macro';
import {color, layout, space} from 'styled-system';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const CheckCircleSvg = ({className, color, theme, height, width}) => (
    <svg className={className} width={width} height={height} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
        <g id='UI' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='0.2.6.3-LG---Onboarding---Verification---Enter-code---Resent'
               transform='translate(-123.000000, -532.000000)'
               fill={theme.colors[color] || color}>
                <g id='icon/status/checkcirclesvg-tick' transform='translate(123.000000, 532.000000)'>
                    <path
                        d='M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M11.0918979,4.8 L6.82683809,9.06395156 L4.85561468,7.26034447 L3.73333333,8.48652024 L6.8777325,11.363703 L12.2666667,5.97476881 L11.0918979,4.8 Z'
                        id='Combined-Shape'></path>
                </g>
            </g>
        </g>
    </svg>
);

const CheckCircle = styled(withTheme(CheckCircleSvg))`
  ${color}
  ${layout}
  ${space}
`;

CheckCircle.defaultProps = {
    color: '#00A908',
    height: 16,
    width: 16,
};

export default CheckCircle;