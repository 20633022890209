const quickFeedOverrides = [
    {
        elementId:'ogf-navigation',
        cssText:`width: 50px !important;
            right: 0 !important;
            top: 50% !important;
            left: auto !important;
            bottom: auto !important;
            padding: 49px 0 0 !important;
            height: 220px !important;
            border-right: 0 !important;
            -webkit-border-top-right-radius: 0px !important;
            -moz-border-top-right-radius: 0px !important;
            -ms-border-top-right-radius: 0px !important;
            -o-border-top-right-radius: 0px !important;
            -khtml-border-top-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
            -webkit-border-top-left-radius: 10px !important;
            -moz-border-top-left-radius: 10px !important;
            -ms-border-top-left-radius: 10px !important;
            -o-border-top-left-radius: 10px !important;
            -khtml-border-top-left-radius: 10px !important;
            border-top-left-radius: 10px !important;
            -webkit-border-bottom-left-radius: 10px !important;
            -moz-border-bottom-left-radius: 10px !important;
            -ms-border-bottom-left-radius: 10px !important;
            -o-border-bottom-left-radius: 10px !important;
            -khtml-border-bottom-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;`
    },
    {
        elementId:'ogf-feedback-label',
        cssText:`-webkit-transform: rotate(-90deg) translate(31px, -14px) !important;
          -moz-transform: rotate(-90deg) translate(31px, -14px) !important;
          -ms-transform: rotate(-90deg) translate(31px, -14px) !important;
          -o-transform: rotate(-90deg) translate(31px, -14px) !important;
          -khtml-transform: rotate(-90deg) translate(31px, -14px) !important;
          transform: rotate(-90deg) translate(31px, -14px) !important;
          text-align: center !important;
          vertical-align: middle !important;
          margin: 26px 0 0 !important;`
    }
];

export const thumbsUpDownForceBottom = () => quickFeedOverrides.forEach(({elementId, cssText})=>
    {
        if(document.getElementById(elementId)){
            document.getElementById(elementId).style.cssText = cssText;
        }
    }
);