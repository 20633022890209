import React from 'react';
import {color, layout} from 'styled-system';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const CopyLinkSvg = ({className, color, theme}) =>
    <svg className={className} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
        <g transform='translate(-200.000000, -550.000000)'>
            <g id='Group' transform='translate(200.000000, 550.000000)'>
                <g id='Group-3'>
                    <polygon id='Path' points='0 0 24 0 24 24 0 24'></polygon>
                    <path d='M3.9,12 C3.9,10.29 5.29,8.9 7,8.9 L11,8.9 L11,7 L7,7 C4.24,7 2,9.24 2,12 C2,14.76 4.24,17 7,17 L11,17 L11,15.1 L7,15.1 C5.29,15.1 3.9,13.71 3.9,12 Z M8,13 L16,13 L16,11 L8,11 L8,13 Z M17,7 L13,7 L13,8.9 L17,8.9 C18.71,8.9 20.1,10.29 20.1,12 C20.1,13.71 18.71,15.1 17,15.1 L13,15.1 L13,17 L17,17 C19.76,17 22,14.76 22,12 C22,9.24 19.76,7 17,7 Z' id='Shape' fill='#2E5299' fill-rule='nonzero' transform='translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) '></path>
                </g>
            </g>
        </g>
    </g>
</svg>;

const CopyLink = styled(CopyLinkSvg)`
  ${color}
  ${layout}
`;

CopyLink.defaultProps = {
    height: 24,
    width: 24,
};

export default withTheme(CopyLink);