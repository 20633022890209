import {UPDATE_USER_PROFILE} from '../user/userReducer';

export const LOCALSTORAGE_KEY_PREFIX = 'bp-';

export const mapBusinessData = (business) => (
    {
        id: business.id,
        name: business.displayName,
        identifier: business.identifier,
        identifierType: business.identifierType,
        entityType: business.abnEntityType,
        businessName: business.businessName
    }
);

const getSelectedBusinessId = (snswCustomerId, businesses) => {
    const storageBusiness = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY_PREFIX + snswCustomerId) || null);
    if (storageBusiness && storageBusiness.selectedBusinessId) {
        const exists = businesses.some(business => {
            return business.id === storageBusiness.selectedBusinessId;
        });
        if (exists) {
            return storageBusiness.selectedBusinessId;
        }
    }
    if (businesses.length > 0) {
        return businesses[0].id;
    } else {
        return '';
    }
};

export const userActions = (dispatch) => ({
    updateUserProfile: (userProfileView) => {
        const {snswCustomerId, email, firstName, lastName, notificationStatus} = userProfileView;
        const businesses = userProfileView.businesses && userProfileView.businesses.map(business => mapBusinessData(business));
        const selectedBusinessId = businesses && getSelectedBusinessId(snswCustomerId, businesses);
        const selectedBusiness = businesses && businesses.find(business => business.id === selectedBusinessId);
        if(selectedBusinessId) {
            localStorage.setItem(LOCALSTORAGE_KEY_PREFIX + snswCustomerId, JSON.stringify({selectedBusinessId}));
        }

        const payload = {
            snswCustomerId,
            email,
            firstName,
            lastName,
            businesses,
            notificationStatus,
            selectedBusinessId,
            selectedBusiness,
        };

        const cleanedPayload = Object.keys(payload).reduce((object, key) => {
            if (payload[key] === undefined) {
                return object;
            } else {
                return {...object, [key]: payload[key]};
            }
        }, {});

        dispatch({
            type: UPDATE_USER_PROFILE,
            payload: {
                ...cleanedPayload
            }
        });
    }
});

export default userActions;