import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

const SearchSvg = ({className, color, theme}) => (
    <svg viewBox='0 0 30 30' className={className} xmlns='http://www.w3.org/2000/svg'>
        <g id='Symbols-(Document)' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Search' transform='translate(-9.000000, -9.000000)' fill={color || theme.colors[color]}>
                <g id='icon/search'>
                    <path d='M28.6885676,30.8395176 C26.6448785,32.4399173 24.0363316,33.4051723 21.202586,33.4051723 C14.4637078,33.4051723 9,27.9414644 9,21.2025862 C9,14.463708 14.4637078,9 21.202586,9 C27.9414642,9 33.4051722,14.463708 33.4051722,21.2025862 C33.4051722,24.0363622 32.4399171,26.6437193 30.8192612,28.7148033 L30.8395205,28.6873932 L39,36.8167573 L36.8335529,38.9832045 L28.7041899,30.8382821 L28.6885676,30.8395176 Z M21.2144836,30.350926 C26.2694049,30.350926 30.3664232,26.2539077 30.3664232,21.1989864 C30.3664232,16.1440651 26.2694049,12.0470468 21.2144836,12.0470468 C16.1595622,12.0470468 12.0625439,16.1440651 12.0625439,21.1989864 C12.0625439,26.2539077 16.1595622,30.350926 21.2144836,30.350926 Z' id='Fill-1'></path>
                </g>
            </g>
        </g>
    </svg>
);

SearchSvg.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    theme: PropTypes.object,
};

const Search = styled(withTheme(SearchSvg))`
  ${color};
  ${layout};
  ${space};
`;

Search.defaultProps = {
    color: '#646974',
    height: 30,
    width: 30,
};

export default Search;