import React from 'react';
import PropTypes from 'prop-types';
import FullWidthLayout from './FullWidthLayout';
import {PageSectionBlock} from './PageLayout.styled';

const PaddedLayout = ({children, backgroundColor, justifyContent}) =>
    <FullWidthLayout backgroundColor={backgroundColor}>
        <PageSectionBlock justifyContent={justifyContent}>
            {children}
        </PageSectionBlock>
    </FullWidthLayout>;

PaddedLayout.propTypes = {
    ...FullWidthLayout.propTypes,
    justifyContent: PropTypes.string,
};

PaddedLayout.defaultProps = {
    ...FullWidthLayout.defaultProps,
    justifyContent: 'space-between',
};

export default PaddedLayout;
