import {createPropTypes} from '@styled-system/prop-types';
import styled from 'styled-components/macro';
import {space} from '@snsw/react-component-library';
import {typography, layout} from 'styled-system';

const Block = styled.div`
  ${space};
  ${layout};
  ${typography};
`;

Block.propTypes = {
    ...createPropTypes(space.propNames),
};

export default Block;