import React, {useEffect, useState} from 'react';
import poiApis from './PoiApis';
import PropTypes from 'prop-types';
import {analyticsUtil, ComponentLoader} from 'common';
import brpApi from './BrpApis';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {isEmpty} from 'lodash';

export const VerificationRedirection = ({location, history, selectedBusiness, supportedEntityTypesForVerification}) => {
    const [supportedEntityTypes, setSupportedEntityTypes] = useState(supportedEntityTypesForVerification);
    const {identifier: abn, entityType, name} = selectedBusiness;

    function gotoServiceUnavailable() {
        history.push('/service-unavailable');
    }

    useEffect(() => {
        if (isEmpty(selectedBusiness)) {
            gotoManageBusiness();
            return;
        }

        isEmpty(supportedEntityTypes) && getSupportedEntityTypes().catch(() => gotoServiceUnavailable());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEmpty(supportedEntityTypes)) {
            return;
        }

        if (!supportedEntityTypes.includes(entityType)) {
            history.goBack();
            return;
        }

        const referenceNumber = new URLSearchParams(location.search.slice(1)).get('referenceNumber');
        if (referenceNumber) {
            initBrp(referenceNumber).then((entryUrl) => {
                sendGAEvent('poi-finished-brp-started');
                entryUrl && window.open(entryUrl, '_self');
            }).catch(() => {
                gotoServiceUnavailable();
            });
            return;
        }

        const brpFinished = new URLSearchParams(location.search.slice(1)).get('brpFinished');

        if (brpFinished) {
            sendGAEvent('brp-finished');
            gotoManageBusiness();
            return;
        }

        initPoi().then((poiCheckUrl) => {
            sendGAEvent('poi-started');
            poiCheckUrl && window.open(poiCheckUrl, '_self');
        }).catch(() => {
            gotoServiceUnavailable();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedEntityTypes]);

    const getSupportedEntityTypes = async () => {
        const response = await brpApi.checkVerificationSupportedEntityTypes();
        setSupportedEntityTypes(response);
    };

    const gotoManageBusiness = () => history.push('/dashboard/manage');

    const initBrp = async (referenceNumber) => {
        const response = await brpApi.initBrp(referenceNumber, abn, name);
        return response.entryUrl;
    };

    const initPoi = async () => {
        const response = await poiApis.initPoi('verification', 'dashboard/manage');
        return response.poiCheckUrl;
    };

    return <StyledLoader><ComponentLoader fullPage/></StyledLoader>;
};

const sendGAEvent = (label) => analyticsUtil.sendGAEvent('business-relationship-proofing', 'bp-brp', label);

const StyledLoader = styled.div`
    > div {opacity:1};
`;

VerificationRedirection.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selectedBusiness: PropTypes.object.isRequired,
    supportedEntityTypesForVerification: PropTypes.array.isRequired,
};

const mapStateToProps = ({user,dashboard}) => {
    return {
        selectedBusiness: user.selectedBusiness,
        supportedEntityTypesForVerification: dashboard.supportedEntityTypesForVerification
    };
};

export default connect(mapStateToProps, null)(VerificationRedirection);