import styled from 'styled-components/macro';

export const BusinessPickerContainer = styled.div`
   display: flex;
   position: absolute; /* Stay in place */
   z-index: 1; /* Sit on top */
   right: 180px;
   top: -66px;

  @media (max-width: ${1024 - 1}px) {
    position: unset;
    margin: 20px 0 0 0;
      flex-direction: column;
  }
`;

export const DropdownContainer = styled.div`
    
    .Dropdown-control {
        border: 0px !important;
        color: rgb(46, 82, 153);
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        line-height: 24px;
        padding: 3px 0px 10px 0px; 
        display: flex;
        
    }
    
    .Dropdown-control:hover{
       box-shadow: unset;
    }
    
    .Dropdown-arrow-wrapper {
        padding: 2px 12px;
    }
    
    .Dropdown-option, .Dropdown-option.is-selected{
        min-height: 1.5rem;
        padding: 0.8125rem 1rem;
        border-top: 1px solid rgb(222, 227, 229); 
        background: unset;
        color: #333;
        
        :first-child{
            border-top:none;
        }
    }
    
    
    .Dropdown-option:hover, .Dropdown-option.is-selected:hover{
        background: rgb(46, 82, 153);
        border-radius: 0px;
        color: #fff; 
    }
    
     
   .Dropdown-menu{
        min-width: 400px;        
        max-height: 235px;
        background: rgb(255, 255, 255);
        border: 2px solid rgb(100, 105, 116);
        border-radius: 0px 0px 5px 5px;
        right: -160px;
        @media (max-width: 1024px) {
            min-width: unset;
            right: 0;
        }
   }
    
    .add-new-business{
       text-decoration: underline;
       color: rgb(46, 82, 153);
       font-weight: 500;
    } 
`;

export const Separator = styled.div`
    border-right: 2px solid rgb(216, 216, 216);
    width: 12px;
    height: 25px;
    margin-top:15px;
    @media (max-width: ${1024 - 1}px) {
       display: none;
    }
`;