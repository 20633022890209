import styled from 'styled-components/macro';
import {space} from 'styled-system';
import PropTypes from 'prop-types';
import {media} from '@snsw/react-component-library';

const SectionBlock = styled.div`
    ${space};
    width: 100%;
    max-width: 76.5rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;

    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: ${props => props.justifyContent || 'unset'};
    margin-top: ${props => props.marginTop || 'unset'};
    
    @media (max-width: ${media.md}px) {
        max-width: ${props => props.fullWidthMobile ? '100% !important' : 'unset'};
        padding-left: ${props => props.fullWidthMobile ? '0 !important' : '1.25rem'};
        padding-right: ${props => props.fullWidthMobile ? '0 !important' : '1.25rem'};
    }
`;

SectionBlock.propTypes = {
    justifyContent: PropTypes.string,
    marginTop: PropTypes.string,
    fullWidthMobile: PropTypes.bool,
};

export default SectionBlock;