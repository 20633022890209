import {stayAndRediscoverBasePath} from '../stayAndRediscover/stayAndRediscoverPaths';
import boolEnv from '../../components/utilities/boolEnv';

export const ANALYTICS = {
    DINE_AND_DISCOVER_CATEGORY: 'dine-discover',
    SMALL_BUSINESS_FEE_WAIVER_CATEGORY: 'small-biz-fees',
    STAY_AND_REDISCOVER_CATEGORY: 'accommodation',
    DEVICE_DEREGISTERED_LABEL: 'device-deregistered',
    DOWNLOAD_TRANSACTION_HISTORY_LABEL: 'download-txn-history',
    OVERVIEW_GET_READY_LABEL: 'get-ready-overview',
    OVERVIEW_GET_STARTED_LABEL: 'overview-get-started',
    OVERVIEW_MORE_INFORMATION_LABEL: 'more-information-overview',
    OVERVIEW_MANAGE_LOCATION: 'overview-manage-locations',
    OVERVIEW_MANAGE_DEVICES: 'overview-manage-devices',
    OVERVIEW_MANAGE_INSURANCE: 'overview-manage-insurance',
    OVERVIEW_TRANSACTIONS: 'overview-txns',
    OVERVIEW_VIEW_CLAIMS: 'new-claim-overview',
    OVERVIEW_NEW_CLAIM: 'new-claim-overview',
    OVERVIEW_HOW_TO_CLAIM: 'how-to-claim-overview',
    OVERVIEW_CLAIM_MORE_INFORMATION: 'more-info-claim-overview',
    OVERVIEW_BANK_DETAILS: 'overview-bank-details',
    MICRO_BUSINESS_GRANT_CATEGORY: 'micro-business-grant',
    JOB_SAVER_GRANT_CATEGORY: 'job-saver-grant',
    JOB_SAVER_ELIGIBILITY_CONFIRMATIONS: 'job-saver-eligibility-confirmations',
    FirstLapCategory: 'first-lap',
    BASCCategory: 'before-and-after-school-care',
    NorthernMediumFloodRebateCategory: boolEnv('ENABLE_MEDIUM_SIZE_GRANT_ENHANCEMENTS')
        ? 'medium-business-support-grant'
        : 'northern-rivers-medium-business-grant',
    SeniorsCardCategory: 'seniors-card',
    CreativeKidsCategory: 'creative-kids',
    ActiveKidsCategory: 'active-creative-kids',
    BackToSchoolCategory: 'back-to-school-pm',
};

export const PROGRAM_CODES = {
    DINE_AND_DISCOVER: 'C19STIMULUSBUSINESSREG',
    STAY_AND_REDISCOVER: 'C19ACCOMMODATIONVOUCHERBUSINESSREG',
    BUSINESSFEEWAIVER: 'BUSINESSFEEWAIVER',
    FLOODRECOVERYGRANT: 'FLOODRECOVERYGRANT',
    JOBSAVERPROGRAM: 'JOBSAVERPROGRAM',
    MICROBUSINESSSUPPORTGRANT: 'MICROBUSINESSSUPPORTGRANT',
    FirstLap: 'FirstLap',
    BASC: 'BASC',
    NorthernMediumFloodRebate: 'NorthernMediumFloodRebate',
    NorthernMediumFloodRebateClaim: 'NorthernMediumFloodRebateClaim',
    SeniorsCard: 'SeniorsCard',
    CreativeKids: 'CreativeKids',
    ActiveCreativeKids: 'ActiveCreativeKids',
    BackToSchool: 'BackToSchool',
};

export const PROGRAM_PATHS = {
    [PROGRAM_CODES.DINE_AND_DISCOVER]: '/dine-and-discover',
    [PROGRAM_CODES.STAY_AND_REDISCOVER]: stayAndRediscoverBasePath,
    [PROGRAM_CODES.FLOODRECOVERYGRANT]: '/flood-recovery-grant',
    [PROGRAM_CODES.BUSINESSFEEWAIVER]: '/sbfv',
    [PROGRAM_CODES.JOBSAVERPROGRAM]: '/job-saver',
    [PROGRAM_CODES.MICROBUSINESSSUPPORTGRANT]: '/micro-business-grant',
    [PROGRAM_CODES.FirstLap]: '/first-lap',
    [PROGRAM_CODES.BASC]: '/before-and-after-school-care',
    [PROGRAM_CODES.NorthernMediumFloodRebate]: boolEnv('ENABLE_MEDIUM_SIZE_GRANT_ENHANCEMENTS')
        ? '/medium-business-support-grant'
        : '/northern-rivers-medium-business-grant',
    [PROGRAM_CODES.NorthernMediumFloodRebateClaim]: boolEnv('ENABLE_MEDIUM_SIZE_GRANT_ENHANCEMENTS')
        ? '/rebate/medium-business-support-grant-claim'
        : '/rebate/northern-rivers-medium-business-grant-claim',
    [PROGRAM_CODES.SeniorsCard]: '/seniors-card',
    [PROGRAM_CODES.CreativeKids]: '/creative-kids',
    [PROGRAM_CODES.ActiveCreativeKids]: '/active-creative-kids',
    [PROGRAM_CODES.BackToSchool]: '/back-to-school',
};

export const VOUCHER_TYPES = {
    DINE: 'CSE',
    DISCOVER: 'CSG',
    STAY_AND_REDISCOVER: 'CSA',
    FirstLap: 'LTS',
    BASC: 'BSC',
    NorthernMediumFloodRebate: 'BDR',
    SeniorsCard: 'SNR',
};

const PROGRAM_GEO_RESTRICTION_ERROR_BASE = 'Please drop a pin on your business location';
export const PROGRAM_GEO_RESTRICTIONS = {
    [PROGRAM_CODES.DINE_AND_DISCOVER]: `${PROGRAM_GEO_RESTRICTION_ERROR_BASE} within NSW`,
    [PROGRAM_CODES.STAY_AND_REDISCOVER]: `${PROGRAM_GEO_RESTRICTION_ERROR_BASE} within the Sydney local government area`,
    [PROGRAM_CODES.FirstLap]: `${PROGRAM_GEO_RESTRICTION_ERROR_BASE} within NSW`,
    [PROGRAM_CODES.BASC]: `${PROGRAM_GEO_RESTRICTION_ERROR_BASE}`,
    [PROGRAM_CODES.SeniorsCard]: `${PROGRAM_GEO_RESTRICTION_ERROR_BASE}`,
};

const requiredLocationFieldsValidations = {
    addressLine: (address) =>
        !!address.addressLine || !!address.formattedAddress || 'Please enter a street address',
    postcode: (address) => !!address.postcode || 'Please enter a postcode',
    suburb: (address) => !!address.suburb || 'Please enter a suburb',
    state: (address) => !!address.state || 'Please select a state',
};
const nswLocationValidations = {
    stateIsNsw: (address) =>
        (!!address.state && (address.state === 'NSW' || address.postcode === '2540')) || 'Please enter a valid NSW address',
    ...requiredLocationFieldsValidations,
};
export const PROGRAM_LOCATION_VALIDATIONS = {
    [PROGRAM_CODES.DINE_AND_DISCOVER]: nswLocationValidations,
    [PROGRAM_CODES.STAY_AND_REDISCOVER]: nswLocationValidations,
    [PROGRAM_CODES.FirstLap]: nswLocationValidations,
    [PROGRAM_CODES.BASC]: requiredLocationFieldsValidations,
    [PROGRAM_CODES.SeniorsCard]: nswLocationValidations,
    [PROGRAM_CODES.ActiveCreativeKids]: nswLocationValidations,
    [PROGRAM_CODES.BackToSchool]: nswLocationValidations,
};

export const PROGRAM_ADDITIONAL_INFO = {
    [PROGRAM_CODES.DINE_AND_DISCOVER]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.DINE_AND_DISCOVER]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.DINE_AND_DISCOVER]}/manage-subscriptions`,
        programName: 'Dine & Discover NSW',
        analyticsCategory: ANALYTICS.DINE_AND_DISCOVER_CATEGORY,
    },
    [PROGRAM_CODES.STAY_AND_REDISCOVER]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.STAY_AND_REDISCOVER]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.STAY_AND_REDISCOVER]}/manage-subscriptions`,
        programName: 'Stay NSW',
        analyticsCategory: ANALYTICS.STAY_AND_REDISCOVER_CATEGORY,
        heading: 'View, download and share transactions',
        description: 'Keep track of voucher transactions in all locations of your business.',
    },
    [PROGRAM_CODES.MICROBUSINESSSUPPORTGRANT]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.MICROBUSINESSSUPPORTGRANT]}/more-information#payments`,
        programName: 'Micro Business Grant',
        analyticsCategory: ANALYTICS.MICRO_BUSINESS_GRANT_CATEGORY,
    },
    [PROGRAM_CODES.JOBSAVERPROGRAM]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.JOBSAVERPROGRAM]}/more-information#payments`,
        programName: 'Job Saver',
        analyticsCategory: ANALYTICS.JOB_SAVER_GRANT_CATEGORY,
    },
    [PROGRAM_CODES.FirstLap]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.FirstLap]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.FirstLap]}/manage-subscriptions`,
        programName: 'First Lap',
        analyticsCategory: ANALYTICS.FirstLapCategory,
        heading: 'View, download and share transactions',
        description: 'Keep track of First Lap voucher transactions in all locations of your business.',
        mfaForBankDetailsEnabled: boolEnv('ENABLE_LTS_BANK_DETAILS_MFA'),
    },
    [PROGRAM_CODES.BASC]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.BASC]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.BASC]}/manage-subscriptions`,
        programName: 'Before and After School Care',
        analyticsCategory: ANALYTICS.BASCCategory,
        heading: 'View, download and share transactions',
        description: 'Keep track of Before and After School Care voucher transactions in all locations of your business.',
        termsAndConditionsUrl: 'https://www.service.nsw.gov.au/basc-voucher-terms-conditions',
    },
    [PROGRAM_CODES.NorthernMediumFloodRebate]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.NorthernMediumFloodRebate]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.NorthernMediumFloodRebate]}/manage-subscriptions`,
        programName: boolEnv('ENABLE_MEDIUM_SIZE_GRANT_ENHANCEMENTS') ? 'Medium Size Business Support Grant' : 'Northern Rivers medium size business grant',
        analyticsCategory: ANALYTICS.NorthernMediumFloodRebateCategory,
        heading: 'View, download and share transactions',
        description: boolEnv('ENABLE_MEDIUM_SIZE_GRANT_ENHANCEMENTS') ? 'Keep track of Medium Size Business Support Grant transactions in all locations of your business.' : 'Keep track of Northern Rivers medium size business grant transactions in all locations of your business.',
        termsAndConditionsUrl: 'https://www.service.nsw.gov.au/basc-voucher-terms-conditions',
        mfaForBankDetailsEnabled: boolEnv('ENABLE_MEDIUM_FLOOD_BANK_DETAILS_MFA'),
    },
    [PROGRAM_CODES.SeniorsCard]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.SeniorsCard]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.SeniorsCard]}/manage-subscriptions`,
        programName: 'Seniors Card',
        analyticsCategory: ANALYTICS.SeniorsCardCategory,
        heading: 'View, download and share transactions',
        description: 'Keep track of First Lap voucher transactions in all locations of your business.',
    },
    [PROGRAM_CODES.CreativeKids]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.CreativeKids]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.CreativeKids]}/manage-subscriptions`,
        programName: 'Creative Kids',
        analyticsCategory: ANALYTICS.CreativeKidsCategory,
        heading: 'View, download and share transactions',
        description: 'Keep track of Creative Kids voucher transactions in all locations of your business.',
    },
    [PROGRAM_CODES.ActiveCreativeKids]: {
        learnMoreLink: `${PROGRAM_PATHS[PROGRAM_CODES.ActiveCreativeKids]}/more-information#payments`,
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.ActiveCreativeKids]}/manage-subscriptions`,
        programName: 'Active Kids',
        analyticsCategory: ANALYTICS.ActiveKidsCategory,
        heading: 'Program transactions',
        description: 'View, download, and share voucher transactions in all locations of your business.',
        pendingTimeToClear: 'Pending transactions may take up to 5 days to show in your account.',
        manageContactsHeading: 'Schedule weekly report',
        manageContactsBody: 'Set up an automatic weekly report for all locations. Transactions will be automatically shared with your chosen contacts every week.',
        manageContactsButtonLabel: 'Schedule report',
        mfaForBankDetailsEnabled: boolEnv('ENABLE_AK_BANK_DETAILS_MFA'),
    },
    [PROGRAM_CODES.BackToSchool]: {
        learnMoreLink: 'https://www.service.nsw.gov.au/back-to-school-vouchers/information-for-businesses#receiving-payments',
        manageEmailGroupLink: `${PROGRAM_PATHS[PROGRAM_CODES.BackToSchool]}/manage-subscriptions`,
        programName: 'Back to School NSW',
        analyticsCategory: ANALYTICS.BackToSchoolCategory,
        heading: 'View, download and share transactions',
        description: 'Keep track of voucher transactions in all locations of your business.',
        termsAndConditionsUrl: 'https://www.service.nsw.gov.au/terms-and-conditions/back-to-school-vouchers-business',
        optInOptOutEnabled: true,
        mfaForBankDetailsEnabled: boolEnv('ENABLE_BTS_BANK_DETAILS_MFA'),
    },
};

export const PROGRAM_NAMES = {
    [PROGRAM_CODES.STAY_AND_REDISCOVER]: 'Stay NSW',
    [PROGRAM_CODES.FirstLap]: 'First Lap',
    [PROGRAM_CODES.BASC]: 'Before and After School Care',
    [PROGRAM_CODES.DINE_AND_DISCOVER]: 'Dine & Discover NSW',
    [PROGRAM_CODES.NorthernMediumFloodRebate]: 'Northern Rivers medium size business grant',
    [PROGRAM_CODES.SeniorsCard]: 'Seniors Card',
    [PROGRAM_CODES.BackToSchool]: 'Back to School NSW',
    [PROGRAM_CODES.ActiveCreativeKids]: 'Active Kids',
};

export const PROGRAM_PAGE_PATHS = {
    moreInformation: (programCode) => `${PROGRAM_PATHS[programCode]}/more-information`,
    downloadMaterials: (programCode) => `${PROGRAM_PATHS[programCode]}/download-materials`,
    getStarted: (programCode) => `${PROGRAM_PATHS[programCode]}/get-started`,
    payments: (programCode) => `${PROGRAM_PATHS[programCode]}/payments`,
    manageDevices: (programCode) => `${PROGRAM_PATHS[programCode]}/manage-devices`,
    manageLocations: (programCode) => `${PROGRAM_PATHS[programCode]}/manage-locations`,
    manageSubscriptions: (programCode) => `${PROGRAM_PATHS[programCode]}/manage-subscriptions`,
};
