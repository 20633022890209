import React from 'react';
import {withTheme} from 'styled-components';
import styled from 'styled-components/macro';
import {color, layout} from 'styled-system';

// eslint-disable-next-line react/prop-types
const CostSvg = ({className, color, theme}) => (
    <svg className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <g id='Icons/cost' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Group' fill={theme.colors[color] || color} fillRule='nonzero'>
                <path d='M11.988,0 C5.364,0 0,5.376 0,12 C0,18.624 5.364,24 11.988,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 11.988,0 Z M12,21.6 C6.696,21.6 2.4,17.304 2.4,12 C2.4,6.696 6.696,2.4 12,2.4 C17.304,2.4 21.6,6.696 21.6,12 C21.6,17.304 17.304,21.6 12,21.6 Z' id='Shape'></path>
                <path d='M15.6268657,7.90654206 L14.7910448,9.22429907 C14.1343284,8.77570093 13.4477612,8.45327103 12.761194,8.31308411 L12.761194,11.0046729 C15.0298507,11.5373832 16,12.3785047 16,13.8785047 L16,13.9065421 C16,15.4345794 14.7164179,16.4579439 12.8208955,16.6261682 L12.8208955,18 L11.5223881,18 L11.5223881,16.5981308 C10.2238806,16.4579439 9,15.9672897 8,15.2102804 L8.95522388,13.9205607 C9.80597015,14.5794393 10.6119403,15 11.5820896,15.1542056 L11.5820896,12.3925234 C9.40298507,11.8598131 8.3880597,11.1028037 8.3880597,9.51869159 L8.3880597,9.49065421 C8.3880597,7.99065421 9.65671642,6.96728972 11.5223881,6.81308411 L11.5223881,6 L12.8208955,6 L12.8208955,6.8411215 C13.8955224,6.96728972 14.7910448,7.3317757 15.6268657,7.90654206 Z M14.238806,13.9766355 C14.238806,13.3598131 13.9104478,12.9813084 12.761194,12.6588785 L12.761194,15.2102804 C13.7164179,15.1121495 14.238806,14.6775701 14.238806,14.0046729 L14.238806,13.9766355 Z M10.1492537,9.40654206 C10.1492537,9.9953271 10.4328358,10.3738318 11.5820896,10.7102804 L11.5820896,8.22897196 C10.6268657,8.31308411 10.1492537,8.77570093 10.1492537,9.39252336 L10.1492537,9.40654206 Z' id='$'></path>
            </g>
        </g>
    </svg>
);

const Cost = styled(withTheme(CostSvg))`
  ${color}
  ${layout}
`;

Cost.defaultProps = {
    color: 'blackBlue',
    height: 24,
    width: 24,
};

export default Cost;