import styled from 'styled-components/macro';
import Header from './components/Header';
import Footer from './components/Footer';
import ContentHeader from './components/ContentHeader';
import Content from './components/Content';
import SecondaryFooter from './components/SecondaryFooter';
import {EtdbButton as Button, media} from '@snsw/react-component-library';
import {StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6} from '../../Heading/Heading';
import {StickyBlock} from '../../Blocks/StickyBlock.styled';
import PageBreadcrumb from './components/PageBreadcrumb';
import PreFooter from './components/PreFooter';
import MobileActionHeader from './components/MobileActionHeader';
import SideBar from './components/SideBar';

const BaseLayoutContainer = styled.div`
  padding-top: ${props => props.theme.space[2.5]}px;

  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(68rem * 0.7) calc(68rem * 0.3);
  grid-template-columns: minmax(100%, 1fr);

  grid-template-areas:
    "breadcrumb"
    "header"
    "content-header"
    "content"
    "pre-footer"
    "footer"
    "secondary-footer";

  & > * {
    margin-bottom: 1.5rem;
  }

  & > *:empty {
    margin-bottom: 0;
  }

  ${PageBreadcrumb} {
    grid-area: breadcrumb;

    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  ${Header} {
    grid-area: header;

    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 2;

    ${StyledH1} ~ p, ${StyledH2} ~ p, ${StyledH3} ~ p, ${StyledH4} ~ p, ${StyledH5} ~ p, ${StyledH6} ~ p {
      margin-top: 0;
    }

    :first-child {
      margin-top: 0;
    }
  }

  ${MobileActionHeader} {
    grid-area: action-header;
    display: none;
  }

  ${ContentHeader} {
    grid-area: content-header;

    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }

  ${Content} {
    grid-area: content;

    -ms-grid-column: 1;
    -ms-grid-row: 4;
  }

  ${SideBar} {
    grid-area: side-bar;
    -ms-grid-column: 2;
    -ms-grid-row: 4;

    //for ie11 only
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-left: 2rem;
    }
  }

  ${PreFooter} {
    grid-area: pre-footer;

    -ms-grid-column: 1;
    -ms-grid-row: 5;
  }

  ${Footer} {
    grid-area: footer;

    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 6;

    flex-direction: column;
    display: flex;
    margin-bottom: 0;
  }

  ${SecondaryFooter} {
    grid-area: secondary-footer;

    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 7;

    flex-direction: column;
    display: flex;
    margin-bottom: 0;
  }

  @media (max-width: ${media.lg}px) {
    padding-top: ${props => props.theme.space[1.5]}px;

    ${MobileActionHeader} {
      display: flex;
    }
  }

  @media (max-width: ${media.sm}px) {
    padding-top: ${props => props.theme.space[1]}px;

    ${Footer} {
      ${StickyBlock} {
        height: unset;
        min-height: 7rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      ${StickyBlock.SectionBlockContent} {
        flex-direction: column;
      }

      ${Button} {
        width: 100%;
      }
    }
  }
`;

export default BaseLayoutContainer;
