import {
    BUSINESS_CONNECT_CLEAR_FILTER,
    BUSINESS_CONNECT_FILTER_FAILURE,
    BUSINESS_CONNECT_FILTER_REQUEST,
    BUSINESS_CONNECT_FILTER_SUCCESS,
    BUSINESS_CONNECT_SET_FILTER,
    BUSINESS_CONNECT_SET_FILTER_INPUT_VALUE,
} from './businessConnectFilterAction';

export const initialState = {
    inputValues: {
        keyword: '',
        postcode: '',
        region: '',
        topic: '',
        industry: '',
        month: '',
    },
    filterValues: {},
    filterOptions: {},
    fetching: true,
    apiError: false,
};

const businessConnectFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_CONNECT_FILTER_REQUEST:
            return {
                ...state,
                fetching: true,
                apiError: false,
            };
        case BUSINESS_CONNECT_CLEAR_FILTER:
            return {
                ...state,
                filterValues: {},
                inputValues: initialState.inputValues,
            };
        case BUSINESS_CONNECT_SET_FILTER_INPUT_VALUE:
            return {
                ...state,
                inputValues: {
                    ...state.inputValues,
                    ...action.payload
                },
            };
        case BUSINESS_CONNECT_SET_FILTER:
            return {
                ...state,
                fetching: true,
                apiError: false,
                filterValues: action.payload,
            };
        case BUSINESS_CONNECT_FILTER_SUCCESS:
            const filterOptions = formatOptions(action.payload);
            return {
                ...state,
                filterOptions,
                fetching: false,
                apiError: false,
            };

        case BUSINESS_CONNECT_FILTER_FAILURE:
            return {
                ...state,
                fetching: false,
                apiError: true,
            };
        default:
            return state;
    }
};

const formatOptions = payload => Object.entries(payload).reduce((acc, [type, options]) => ({
    ...acc,
    [type]: options.sort((option1, option2) => {
        if (typeof option1 === 'string') {
            return 0;
        }
        return (option1.title < option2.title) ? -1 : (option1.title > option2.title) ? 1 : 0;
    }).map(item => {
        const value = typeof item === 'string' ? item : item.title;
        return {text: value, value: value};
    }),
}), {});

export default businessConnectFilterReducer;
