import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {PageContainer, PageContentAndFooterContainer, ScrollToTop} from 'common';
import externalScriptHandler, {loadWebChat, removeGlobalNav} from '../utilities/externalScriptHandler';
import {LoggedInHeader} from '../../dashboard/LoggedInHeader';
import {LoggedInFooter} from '../../dashboard/LoggedInFooter';
import boolEnv from '../utilities/boolEnv';

const isNewHeaderEnabled = (location, includedUrls) => {
    if (!location) {
        return false;
    } else {
        if (!boolEnv('ENABLE_MAINTENANCE')) {
            return includedUrls.find(url => location.pathname.includes(url));
        } else {
            return false;
        }
    }
};

export const Page = props => {
    const {
        component: Component,
        layout: Layout,
        scrollToTop,
        includedPages,
        fullHeight,
        ...otherProps
    } = props;
    const {user, location, history} = otherProps;
    const useNewHeader = isNewHeaderEnabled(location, includedPages);

    useEffect(() => {
        if (useNewHeader) {
            removeGlobalNav();
            loadWebChat();
        } else {
            externalScriptHandler(user.email);
        }
    }, [user.email, useNewHeader]);

    return <>
        <PageContainer>
            <PageContentAndFooterContainer fullHeight={fullHeight}>
                    {useNewHeader && <LoggedInHeader user={user} history={history} location={location}/>}
                    <Layout>
                        <Component {...otherProps} loggedIn={!!user.email} user={user}/>
                    </Layout>
                    {useNewHeader && <LoggedInFooter/>}
            </PageContentAndFooterContainer>
        </PageContainer>
        {scrollToTop && <ScrollToTop/>}
    </>;
};

Page.propTypes = {
    layout: PropTypes.func.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]).isRequired,
    scrollToTop: PropTypes.bool,
    user: PropTypes.object,
    location: PropTypes.object,
    includedPages: PropTypes.array.isRequired,
    fullHeight: PropTypes.bool,
};

Page.defaultProps = {
    includedPages: [
        '/dashboard',
        '/add-business',
        '/dine-and-discover',
        '/first-lap',
        '/back-to-school',
        '/before-and-after-school-care',
        '/job-saver',
        '/micro-business-grant',
        '/sbfv',
        '/stay-and-rediscover',
        '/licences',
        '/notifications',
        '/redirect-page',
        '/verification',
        '/active-creative-kids'
    ],
    fullHeight: false,
};

export const mapStateToProps = ({user}) => ({
    user,
});

export default connect(mapStateToProps)(Page);
