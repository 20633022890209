import React, {useEffect} from 'react';
import {InPageAlert} from '@snsw/react-component-library';
import {analyticsUtil} from '../utilities/analyticsUtil';

export const InPageAlertWithAnalytics = (props) => {

    useEffect(() => {
        if (props.variant !== 'info') {
            analyticsUtil.pushAlertAnalytics(
                {
                    type: `alert ${props.variant}`,
                    name: 'page notice',
                    text: props.title,
                    errorPos: 'page content',
                },
            );
        }
    }, [props.title, props.variant]);

    return <InPageAlert {...props} />;
};
