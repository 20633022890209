import NoLayout from './NoLayout';
import WhiteLayout from './WhiteLayout';
import ContentLayout from './ContentLayout';
import PaddedLayout from './PaddedLayout';
import SideBarLayout, {SideBar5050Layout} from './layouts/SideBar/SideBarLayout';
import GelLandingPageLayout from './layouts/Gel/GelLandingPageLayout';
import MySNSWLandingPageLayout from './layouts/MySNSW/MySNSWLandingPageLayout';
import GelTransactionPageLayout from './layouts/Gel/GELTransactionPageLayout';
import SideBarTopLayout from './layouts/SideBar/SideBarTopLayout';
import GelBizRelationshipProofingLayout from './layouts/Gel/GelBizRelationshipProofingLayout';

export const PageLayout = {
    NONE: NoLayout,
    WHITE: WhiteLayout,
    GREY: PaddedLayout,
    GREY_CONTENT: ContentLayout,

    LANDING_PAGE: NoLayout,
    QUESTIONNAIRE: NoLayout,

    SIDE_BAR: SideBarLayout,
    SIDE_BAR_TOP: SideBarTopLayout,
    SIDE_BAR_5050: SideBar5050Layout,

    GEL_LP: GelLandingPageLayout,
    GEL_TL: GelTransactionPageLayout,
    MYSNSW_LP: MySNSWLandingPageLayout,
    GEL_BRP: GelBizRelationshipProofingLayout
};

export default PageLayout;
