import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const GradingSvg = ({className, color, theme, width, height}) => (
    <svg className={className} width={width} height={height} viewBox='0 0 32 32'
         xmlns='http://www.w3.org/2000/svg'>
        <title>225024D0-358A-49B7-93FD-FCC8983C44B6@1.00x</title>
        <g id='BC-Form---Iteration-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='2.0:-LG---Iteration-1---Success' transform='translate(-74.000000, -840.000000)'>
                <g id='grading-24px' transform='translate(74.000000, 840.000000)'>
                    <rect id='Rectangle' x='0' y='0' width='32' height='32'></rect>
                    <path
                        d='M5.33333333,9.33333333 L26.6666667,9.33333333 L26.6666667,12 L5.33333333,12 L5.33333333,9.33333333 Z M5.33333333,17.3333333 L26.6666667,17.3333333 L26.6666667,14.6666667 L5.33333333,14.6666667 L5.33333333,17.3333333 Z M5.33333333,22.6666667 L14.6666667,22.6666667 L14.6666667,20 L5.33333333,20 L5.33333333,22.6666667 Z M5.33333333,28 L14.6666667,28 L14.6666667,25.3333333 L5.33333333,25.3333333 L5.33333333,28 Z M20.5466667,24.2266667 L18.6666667,22.3333333 L16.7866667,24.2133333 L20.5466667,28 L26.6666667,21.8933333 L24.7733333,20 L20.5466667,24.2266667 Z M5.33333333,4 L5.33333333,6.66666667 L26.6666667,6.66666667 L26.6666667,4 L5.33333333,4 Z'
                        id='Shape' fill={theme.colors[color] || color} fillRule='nonzero'></path>
                </g>
            </g>
        </g>
    </svg>
);

const Grading = styled(withTheme(GradingSvg))`
  ${color};
  ${layout};
  ${space};
`;

Grading.defaultProps = {
    color: '#E2173D',
    height: 32,
    width: 32,
    marginRight: '0.5rem'
};

export default Grading;