import React from 'react';
import styled from 'styled-components/macro';
import {color, layout} from 'styled-system';
import {withTheme} from 'styled-components';

const Background = styled.span`
  ${color}
  ${layout}
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px;
  background-color: ${props => props.fileType.color};
  display: flex;
  align-items: center;
  font-size: .5rem;
  font-weight: bold;
  line-height: 1.25;
  color: ${props => props.theme.colors.white};
  justify-content: center;
`;

const PDF = (props) =>
    <Background {...props}>{props.fileType.name}</Background>;

export default withTheme(PDF);