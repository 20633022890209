import {api} from 'common';

const businessConnectApi = {
    getAdvisors: async (filters = {}) => {
        const queryString = api.getQueryParameters(filters);
        return await api.getSecure(`/api/crsb/user/business-connect/advisors${queryString}`);
    },

    getEvents: async (filters = {}) => {
        const queryString = api.getQueryParameters(filters);
        return await api.getSecure(`/api/crsb/user/business-connect/events${queryString}`);
    },

    getAdvisorDetail: async (advisorId) => {
        try {
            return await api.getSecure('/api/crsb/user/business-connect/advisor/' + advisorId);
        } catch (e) {
            return [];
        }
    },

    bookAdvisor: async (advisorId) => {
        try {
            return await api.postSecure(`/api/crsb/user/business-connect/advisor/${advisorId}/booking`);
        } catch (e) {
            return '';
        }
    },

    bookEvent: async (sessionId) => {
        try {
            return await api.postSecure(`/api/crsb/user/business-connect/event/${sessionId}/booking`);
        } catch (e) {
            return '';
        }
    },

    getFilterOptions: async (type) => {
        try {
            return await api.getSecure(`/api/crsb/user/business-connect/segments/${type}`);
        } catch (e) {
            return [];
        }
    }
};

export default businessConnectApi;
