import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {Route, Switch, withRouter} from 'react-router';
import {OAuthRedirect} from 'common';
import GlobalNavigation from './GlobalNavigation';

export const OauthRedirectMyBusiness = (props) => <OAuthRedirect redirectTo={'/dashboard'} {...props}/>;

export const AppSwitch = ({location}) => <>
    <Helmet>
        <link rel='canonical' href={location.pathname}/>
    </Helmet>
    <Switch>
        <Route exact path='/oauth-redirect' component={OauthRedirectMyBusiness}/>
        <Route path='/**' component={GlobalNavigation}/>
    </Switch>
</>;

AppSwitch.propTypes = {
    location: PropTypes.object.isRequired
};

export default withRouter(AppSwitch);