import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {flexbox} from 'styled-system';
import {StickyBlock} from '../../../Blocks/StickyBlock.styled';
import SectionBlock from '../../../Blocks/SectionBlock.styled';

const FooterContainer = ({children, className, level, fullWidth = false}) => (
    <div className={className}>
        {
            fullWidth
                ?
                <>{children}</>
                :
                <StickyBlock level={level}>
                    <SectionBlock>
                        <StickyBlock.SectionBlockContent>
                            {children}
                        </StickyBlock.SectionBlockContent>
                    </SectionBlock>
                </StickyBlock>
        }
    </div>
);

FooterContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    level: PropTypes.oneOf(['primary', 'secondary', 'pre']),
    fullWidth: PropTypes.bool
};

FooterContainer.defaultProps = {
    level: 'primary',
};

const Footer = styled(FooterContainer)`
  ${flexbox};
`;

export default Footer;