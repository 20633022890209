import env from '@beam-australia/react-env';

const boolEnv = (flag) => {
    const value = env(flag);
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
    return value;
};

export default boolEnv;