import React from 'react';
import env from '@beam-australia/react-env';
import PropTypes from 'prop-types';

const SnswMyAccountLink = ({onClick}) => {
    const myAccountLink = env('NODE_ENV') === 'production' ?
        'https://account.service.nsw.gov.au' :
        'https://myservicensw-account-ui.staging.my.testservicensw.net';
    return (
        <a href={myAccountLink} onClick={onClick && onClick()}>MyServiceNSW Account</a>
    );
};

SnswMyAccountLink.propTypes = {
    onClick: PropTypes.func,
};

export default SnswMyAccountLink;