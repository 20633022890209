import React from 'react';
import PropTypes from 'prop-types';
import {Switch} from 'react-router';
import PageRoute from './PageRoute';
import NotFound from './NotFound';
import {PageLayout} from 'common';

const Navigation = ({children}) =>
    <Switch>
        {children}
        <PageRoute layout={PageLayout.WHITE} path='/*' component={NotFound}/>
    </Switch>;

Navigation.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Navigation;