import React from 'react';
import ContentLayout from '../../ContentLayout';
import {GELTransactionLPageLayoutStyled} from './GELTransactionLPageLayout.styled';

const GelTransactionPageLayout = ({children}) => {
    return (
        <>
            <GELTransactionLPageLayoutStyled aria-label='Main content'>
                {children}
            </GELTransactionLPageLayoutStyled>
        </>
    );
};

GelTransactionPageLayout.propTypes = {
    ...ContentLayout.propTypes,
};

GelTransactionPageLayout.defaultProps = {
    ...ContentLayout.defaultProps,
};

export default GelTransactionPageLayout;