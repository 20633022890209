import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

const ThumbsDownSvg = ({className, color, theme, selected = false}) =>
    <svg className={className} width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
        <title>Icons/ThumbsDown</title>
        <g id='thumbs-down' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <circle id='oval' stroke={color || theme.colors[color]} strokeWidth='2'
                    fill={selected ? color || theme.colors[color] : 'none'} cx='12'
                    cy='12' r='11'/>
            <g id='thumbs'
               transform='translate(12.000000, 12.000000) scale(-1, 1) rotate(-180.000000) translate(-12.000000, -12.000000) translate(6.600000, 6.600000)'
               fill={selected ? '#FFFFFF' : color || theme.colors[color]}
               fillRule='nonzero'>
                <path d='M0.675,10.8 C0.302207794,10.8 0,10.4977922 0,10.125 L0,4.725 C0,4.35220779 0.302207794,4.05 0.675,4.05 C1.04779221,4.05 1.35,4.35220779 1.35,4.725 L1.35,10.125 C1.35,10.4977922 1.04779221,10.8 0.675,10.8 Z' id='Path'/>
                <path d='M8.1,10.8000005 L3.375,10.8000005 C3.00220779,10.8000005 2.7,10.4977922 2.7,10.125 L2.7,4.725 C2.70063354,4.61933398 2.72606589,4.51529255 2.77425,4.42125 L4.79925,0.37125 C4.91362891,0.144261235 5.14582321,0.00077038021 5.4,0 C6.51837662,0 7.425,0.906623382 7.425,2.025 L7.425,3.375 L8.775,3.375 C9.89337662,3.375 10.8,4.28162338 10.8,5.4 C10.8033133,5.44493901 10.8033133,5.49006099 10.8,5.535 L10.125,8.84925 C10.0850332,9.93852766 9.19001038,10.800733 8.1,10.8000005 Z M4.05,9.45 L8.13375,9.45 C8.49330012,9.43200026 8.77545027,9.13500011 8.775,8.775 C8.77168669,8.73006099 8.77168669,8.68493901 8.775,8.64 L9.45,5.346 C9.42178701,4.99444519 9.12768325,4.72386974 8.775,4.725 L6.75,4.725 C6.37720779,4.725 6.075,4.42279221 6.075,4.05 L6.075,2.025 C6.07461768,1.79550534 5.9576581,1.58192697 5.7645,1.458 L4.05,4.887 L4.05,9.45 Z' id='Shape'/>
            </g>
        </g>
    </svg>;

ThumbsDownSvg.propTypes = {
    selected: PropTypes.bool
};

const ThumbsDown = styled(withTheme(ThumbsDownSvg))`
  ${color}
  ${layout}
  ${space}
`;

ThumbsDown.defaultProps = {
    color: '#2E5299',
};

export default ThumbsDown;
