import React, {useEffect} from 'react';
import {Textarea} from '@snsw/react-component-library';
import {analyticsUtil} from '../utilities/analyticsUtil';

export const TextareaWithAnalytics = (props) => {
    useEffect(() => {
        if (props.hasError && props.errorMessage) {
            analyticsUtil.pushAlertAnalytics(
                {
                    type: 'alert error',
                    name: 'in-page alert',
                    text: props.errorMessage,
                    errorPos: 'page content',
                },
            );
        }
    }, [props.hasError, props.errorMessage]);

    return <Textarea {...props} />;
};