import {put, takeLatest, call, select} from 'redux-saga/effects';
import transactionApi from './transactionApi';
import {actionKey, storeKey} from './transactionReducer';
import {fetchFailure, initializeSuccess, saveDataRequested} from './transactionActions';

export function* initialize({payload}) {
    try {
        const state = yield call(transactionApi.initialize, payload.type);
        yield put(initializeSuccess({...payload, ...state}));
    } catch (error) {
        yield put(fetchFailure(error));
    }
}

export function* saveAnswer({payload: {stateId, answer, callback}}) {
    const {stateChart: {type}} = yield select(state => state[storeKey]);
    try {
        const processResult = yield call(transactionApi.process, type, stateId, answer);
        yield put(saveDataRequested({stateId, ...processResult}));
        callback({currentState: processResult.nextState});
    } catch (error) {
        yield put(fetchFailure(error));
    }
}

export default function* transactionSaga() {
    yield takeLatest(`${actionKey}/INITIALIZE_REQUESTED`, initialize);
    yield takeLatest(`${actionKey}/SAVE_ANSWER_REQUEST`, saveAnswer);
}
