import businessConnectApi from '../businessConnectApi';
import { put, takeEvery } from 'redux-saga/effects';
import {BUSINESS_CONNECT_ADVISOR_REQUEST, BUSINESS_CONNECT_ADVISOR_FAILURE, BUSINESS_CONNECT_ADVISOR_SUCCESS} from './businessAdvisorAction';

function* fetchAdvisors(action) {
    try {
        const payload = yield businessConnectApi.getAdvisors(action.payload);
        yield put({ type: BUSINESS_CONNECT_ADVISOR_SUCCESS, payload: payload });
    } catch(e) {
        yield put({ type: BUSINESS_CONNECT_ADVISOR_FAILURE});
    }
}

export default function* businessConnectSaga() {
    yield takeEvery(BUSINESS_CONNECT_ADVISOR_REQUEST, fetchAdvisors);
}
