import {api} from 'common';

const brpApi = ({
    initBrp(poiId, abn, businessName) {
        return api.postSecure('/user/business/verification/initialise', {
            poiId: poiId,
            selectedBusiness: {
                identifier: abn,
                identifierType: 'ABN',
                businessName: businessName,
            }
        });
    },

    checkVerificationStatus(abn) {
        return api.getSecure(`/user/business/verification/status/ABN/${abn}`);
    },

    checkVerificationSupportedEntityTypes() {
        return api.getSecure('/user/business/verification/supported-entity-types');
    }
});

export default brpApi;