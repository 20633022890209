import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Icons} from 'common';
import {connect} from 'react-redux';
import {MenuButton, MenuButtonContainer} from './NavBar.styled';
import {UPDATE_NAV_MENU_REQUEST} from './navMenuReducer';

const NavMenuButton = ({open, onOpenClick}) => {
    const previousOpenState = useRef(false);

    useEffect(() => {
        // Testing is covered by KeyboardUserTestA11y.kt
        if (previousOpenState.current === true) {
            const menuButton = document.querySelector('[data-testid="nav-menu"]');
            if (menuButton) {
                setTimeout(() => menuButton.focus(), 50);
            }
        }
        previousOpenState.current = open;
    }, [open]);

    return (
        <MenuButtonContainer>
            <MenuButton
                data-testid='nav-menu'
                type='button'
                open={open}
                onClick={onOpenClick}
                aria-expanded={open}
                tabIndex={0}
            >
                <Icons.Menu/>
                <span>Menu</span>
            </MenuButton>
        </MenuButtonContainer>
    );
};

NavMenuButton.propTypes = {
    open: PropTypes.bool.isRequired,
    onOpenClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({navMenu}) => ({
    open: navMenu.open,
});

const mapDispatchToProps = (dispatch) => ({
    onOpenClick: () => {
        dispatch({type: UPDATE_NAV_MENU_REQUEST, payload: {open: true}});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenuButton);
