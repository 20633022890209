import React from 'react';
import Navigation from '../components/routing/Navigation';
import LoggedInBaseLayout from '../dashboard/LoggedInBaseLayout';
import {Route} from 'react-router';

export const MaintenancePageLazy = React.lazy(() =>
    import('./maintenance/MaintenancePage')
);

const MaintenanceRedirects = () => {

    return <Navigation>
        <Route
            layout={LoggedInBaseLayout}
            path={'/*'}
            component={MaintenancePageLazy}
        />
    </Navigation>;
};

export default MaintenanceRedirects;