import {fork} from 'redux-saga/effects';
import businessAdvisorSaga from '../transactions/businessConnect/advisors/businessAdvisorSaga';
import businessConnectFilterSaga from '../transactions/businessConnect/businessConnectFilterSaga';
import businessEventSaga from '../transactions/businessConnect/events/businessEventSaga';
import {transactionSaga} from 'transaction';

export default function* watchMany() {
    yield fork(businessAdvisorSaga);
    yield fork(businessConnectFilterSaga);
    yield fork(businessEventSaga);
    yield fork(transactionSaga);
}
