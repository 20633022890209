import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {media} from '@snsw/react-component-library';
import {space} from 'styled-system';
import {StyledH1, StyledH2, StyledH4, StyledH5, StyledH6} from '../Heading/Heading';

export const MainTitle = styled(StyledH1)`
    margin-top: 1.25rem;
`;

export const ContentTitle = styled(StyledH2)`
    margin-bottom: 0.75rem;
`;

export const SubTitle = styled(StyledH4)`
    margin: 0;
`;

export const SectionTitle = StyledH5;

export const SectionSubTitle = styled(StyledH6)`
    margin: ${props => props.margin || '0 0 1rem 0 !important'};
`;

SectionSubTitle.propTypes = {
    margin: PropTypes.string,
};

export const CopyBlock = styled.div`
  max-width: 55.625rem;
  font-size: 1rem;
`;

export const CopyText = styled.p`
  max-width: 55.625rem;
  font-size: 1rem;
`;

export const CopyTextItalic = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: italic;
  font-weight: 300;
  color: #646974;
`;

export const SupportCopy = styled.div`
    max-width: 55.625rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    ul {
        padding-left: 1rem;
        li {
            margin-bottom: 1rem;
        }
    }
`;

export const ReactiveText = styled.div`
    font-size: 1rem;
    line-height: 1.45rem;
    font-weight: ${props => props.bold ? '500' : 'normal'};
        ${props => props.fontColor && `
        color: ${props.fontColor};
    `};

    @media (max-width: ${media.xs}px) {
        font-size: 0.875rem;
    }
    ${space}
`;

export const StrongText = styled.span`
    font-weight: 700;
`;

export const SecondaryText = styled.span`
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: ${props => props.bold ? '500' : 'normal'};
    ${props => props.fontColor && `
        color: ${props.fontColor};
    `};
`;

export const SmallText = styled.span`
    font-size: 0.75rem;
    line-height: 1;
`;

export const CopyTextSmall = styled.p`
    font-size: 0.875rem;
    font-weight: ${props => props.bold ? '500' : 'normal'};
    line-height: 1.125;
`;
