import React from 'react';
import PropTypes from 'prop-types';
import {MaxWidthContainer} from './HeaderFooter.styled';
import Feedback from './feedback/Feedback';

const LoggedInBaseLayout = ({children}) => {
    return (
        <>
            <MaxWidthContainer style={{marginTop: '24px', marginBottom: '56px'}}>
                {children}
            </MaxWidthContainer>
            <Feedback/>
        </>
    );
};

LoggedInBaseLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default LoggedInBaseLayout;
