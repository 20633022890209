import dashboardStore from './dashboardStore';

export default (state = dashboardStore, action) => {
    switch (action.type) {
        case DASHBOARD_CLOSE_ERROR: {
            return {
                ...state,
                showError: false,
            };
        }
        case DASHBOARD_FETCH_LICENCE:
            return {
                ...state,
                fetchingLicence: 'PENDING',
            };
        case DASHBOARD_LICENCE_FOUND: {
            const {licence, lastFetchedLicenceNumber} = action.payload;
            return {
                ...state,
                licence,
                lastFetchedLicenceNumber: lastFetchedLicenceNumber || '',
                fetchingLicence: 'FETCHED',
            };
        }
        case DASHBOARD_SET_LICENCE_EXPIRY_NOTIFICATION:
            const {savedLicenceDetails} = action.payload;
            return {
                ...state,
                savedLicenceDetails
            };
        case DASHBOARD_DELTA_UPDATE_NOTIFICATIONS_UNREAD_COUNT: {
            const unreadNotificationsCount = state.unreadNotificationsCount + action.payload.delta;
            return {
                ...state,
                unreadNotificationsCount
            };
        }
        case DASHBOARD_UPDATE_NOTIFICATIONS_UNREAD_COUNT: {
            return {
                ...state,
                unreadNotificationsCount: action.payload
            };
        }
        case DASHBOARD_SET_SUPPORTED_ENTITY_TYPE_FOR_VERIFICATION: {
            return {
                ...state,
                supportedEntityTypesForVerification: action.payload
            };
        }
        default:
            return state;
    }
};

export const DASHBOARD_CLOSE_ERROR = 'DASHBOARD_CLOSE_ERROR';
export const DASHBOARD_FETCH_LICENCE = 'FETCH_LICENCE';
export const DASHBOARD_LICENCE_FOUND = 'LICENCE_FOUND';
export const DASHBOARD_SET_LICENCE_EXPIRY_NOTIFICATION = 'SET_LICENCE_EXPIRY_NOTIFICATION';
export const DASHBOARD_DELTA_UPDATE_NOTIFICATIONS_UNREAD_COUNT = 'DELTA_UPDATE_NOTIFICATIONS_UNREAD_COUNT';
export const DASHBOARD_UPDATE_NOTIFICATIONS_UNREAD_COUNT = 'UPDATE_NOTIFICATIONS_UNREAD_COUNT';
export const DASHBOARD_SET_SUPPORTED_ENTITY_TYPE_FOR_VERIFICATION = 'DASHBOARD_SET_SUPPORTED_ENTITY_TYPE_FOR_VERIFICATION';
