import React from 'react';
import * as PropTypes from 'prop-types';

const Notification = ({size, color}) =>
    <svg width={`${size}px`} height={`${size}px`} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <title>icon/notification</title>
        <desc>Created with Sketch.</desc>
        <g id='Symbols-(Document)' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Header/Desktop/Menu-bar' transform='translate(-1128.000000, -28.000000)' fill={color}>
                <g id='icon/notification/default' transform='translate(1124.000000, 24.000000)'>
                    <g id='Group' transform='translate(4.000000, 4.000000)'>
                        <path
                            d='M19.5,7.5 C19.5,3.3585 16.1415,0 12,0 C7.8585,0 4.5,3.3585 4.5,7.5 L4.5,15 L0,18 L0,19.5 L24,19.5 L24,18 L19.5,15 L19.5,7.5 Z'
                            id='Path'
                            fillRule='nonzero'
                        />
                        <path
                            d='M15,21 L9,21 C9,22.6575 10.3425,24 12,24 C13.6575,24 15,22.6575 15,21 Z'
                            id='Path'
                            fillRule='nonzero'
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>;

Notification.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

Notification.defaultProps = {
    size: 24,
    color: '#002554',
};

export default Notification;