import styled from 'styled-components/macro';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PageContentAndFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: ${({fullHeight}) => fullHeight ? '100vh' : 'unset'}
`;
