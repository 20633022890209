import React from 'react';
import styled from 'styled-components/macro';
import {withTheme} from 'styled-components';
import {color, layout, space} from 'styled-system';

// eslint-disable-next-line react/prop-types
const LocationSvg = ({className, color, theme}) => (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <path fill={theme.colors[color] || color}
              d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z'></path>
    </svg>
);

const Location = styled(withTheme(LocationSvg))`
  ${color};
  ${layout};
  ${space};
`;

Location.defaultProps = {
    color: '#c4d0d6',
    height: 26,
    width: 24,
    marginRight: '0.5rem'
};

export default Location;