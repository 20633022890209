import React from 'react';
import {withTheme} from 'styled-components';
import styled from 'styled-components/macro';
import {color, layout} from 'styled-system';

// eslint-disable-next-line react/prop-types
const LockSvg = ({className, color, theme}) => (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
        <g id='Icons/cost' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='Group' fill={theme.colors[color] || color} fillRule='nonzero'>
                <path d='M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z'/>
            </g>
        </g>
    </svg>
);

const Lock = styled(withTheme(LockSvg))`
  ${color}
  ${layout}
`;

Lock.defaultProps = {
    color: 'blackBlue',
    height: 11,
    width: 10,
};

export default Lock;