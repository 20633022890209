import React from 'react';
import {withTheme} from 'styled-components';
import styled from 'styled-components/macro';
import {color, layout} from 'styled-system';

// eslint-disable-next-line react/prop-types
const WarningSvg = ({className, color, theme}) => (
    <svg className={className} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <path
                d='M14.84368,2.31475289 L22.9686969,18.5647867 C23.779466,20.1863248 23.1222078,22.1580993 21.5006697,22.9688684 C21.0448616,23.1967724 20.5422514,23.3154226 20.0326425,23.3154226 L3.7826087,23.3154226 C1.96967397,23.3154226 0.5,21.8457486 0.5,20.0328139 C0.5,19.523205 0.618650192,19.0205947 0.846554221,18.5647867 L8.9715711,2.31475289 C9.78234015,0.693214783 11.7541147,0.0359566009 13.3756528,0.846725656 C14.0109275,1.16436302 14.5260427,1.67947816 14.84368,2.31475289 Z M12.6076121,3.43278688 C12.531884,3.28133065 12.4090751,3.15852175 12.2576188,3.08279363 C11.871027,2.8894977 11.400935,3.04619502 11.2076391,3.43278688 L3.0826222,19.6828206 C3.02828746,19.7914901 3,19.9113177 3,20.0328139 C3,20.4650367 3.35038585,20.8154226 3.7826087,20.8154226 L20.0326425,20.8154226 C20.1541386,20.8154226 20.2739662,20.7871351 20.3826357,20.7328004 C20.7692276,20.5395045 20.9259249,20.0694125 20.7326289,19.6828206 L12.6076121,3.43278688 Z'
                id='Rectangle' fill={theme.colors[color] || color} fillRule='nonzero'></path>
            <path
                d='M11.972843,7.51107476 C12.8013897,7.51107476 13.4730596,8.18274463 13.4730596,9.01129141 C13.4730596,9.05287402 13.4713308,9.09443865 13.4678775,9.13587762 L13.032344,14.36228 C12.9864244,14.9133144 12.5257874,15.3371617 11.972843,15.3371617 C11.4198985,15.3371617 10.9592615,14.9133144 10.913342,14.36228 L10.4778084,9.13587762 C10.4090014,8.31019285 11.022572,7.58506393 11.8482568,7.51625687 C11.8896957,7.51280362 11.9312604,7.51107476 11.972843,7.51107476 Z'
                id='Rectangle' fill={theme.colors[color] || color}></path>
            <rect id='Rectangle-Copy-2' fill={theme.colors[color] || color} x='10.6684951' y='16.3154226' width='2.60869565'
                  height='2.60869565' rx='1.30434783'></rect>
        </g>
    </svg>
);

const Warning = styled(withTheme(WarningSvg))`
  ${color}
  ${layout}
`;

Warning.defaultProps = {
    color: 'blackBlue',
    height: 24,
    width: 24,
};

export default Warning;