import {
    BUSINESS_CONNECT_ADVISOR_FAILURE,
    BUSINESS_CONNECT_ADVISOR_REQUEST,
    BUSINESS_CONNECT_ADVISOR_SUCCESS
} from './businessAdvisorAction';

const initialState = {
    advisors: [],
    fetching: false,
    apiError: false,
};

const businessAdvisorReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUSINESS_CONNECT_ADVISOR_REQUEST:
            return {
                ...state,
                fetching: true,
                apiError: false,
            };
        case BUSINESS_CONNECT_ADVISOR_SUCCESS:
            return {
                ...state,
                advisors: action.payload,
                fetching: false,
                apiError: false,
            };

        case BUSINESS_CONNECT_ADVISOR_FAILURE:
            return {
                ...state,
                fetching: false,
                apiError: true,
            };
        default:
            return state;
    }
};

export default businessAdvisorReducer;
