import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {css} from 'styled-components';
import {media, PrimaryButton, SecondaryButton, TertiaryButton, th} from '@snsw/react-component-library';
import SectionBlock from './SectionBlock.styled';

const blockLevelMap = {
    primary: css`
      background-color: ${th.lightGrey2};
      height: 7rem;
      margin-top: 1rem;
      align-items: center;
    `,
    secondary: css`
      background-color: ${th.white};
      align-items: center;

      ${SectionBlock} {
        padding-top: 2rem;
        padding-bottom: 2rem;

        @media (max-width: ${media.xs}px) {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    `,
    pre: css`
      background-color: ${th.greyBackground};
      align-items: center;
    `,
    tertiary: css`
      background-color: ${th.white};
      height: 7rem;
      align-items: flex-start;
    `,
};

export const StickyBlock = styled.div`
    ${(props) => blockLevelMap[props.level]};
    width: 100vw;
    
    align-self: center;
    
    display: flex;
    justify-content: center;
`;

StickyBlock.propTypes = {
    level: PropTypes.string
};

StickyBlock.defaultProps = {
    level: 'primary'
};

export const StickyBlockColumn = styled(StickyBlock)`
    flex-direction: column;
    height: 10rem;
    
    @media (max-width: ${media.xs}px) {
      height: unset;
      min-height: 7.5rem;  
    }
`;

StickyBlock.Content = styled.div`
    width: 100%;
    max-width: ${props => props.theme.componentWidths.screener.footer.content};
    
    display: flex;
    align-items: center;
    justify-content: ${props => props.leftAligned ? 'flex-start' : 'flex-end'} ;
    
    @media (max-width: ${media.md}px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    
    @media (max-width: ${media.xs}px) {
      ${PrimaryButton} {
        width: 100%;
      }
    }
`;

StickyBlock.Content.propTypes = {
    leftAligned: PropTypes.bool
};

StickyBlock.SectionBlockContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-right: 2rem;
  width: 70%;

  @media (max-width: ${media.lg}px) {
    padding-right: 0;
    width: 100%;
  }
`;

StickyBlock.QuestionnaireContent = styled(StickyBlock.Content)`
    justify-content: space-between;
    
    ${TertiaryButton} {
      margin-right: 2.5rem;
    }
    
    @media (max-width: ${media.xs}px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    
    ${props => props.splitRowOnMobile && css`
        ${TertiaryButton} {
          margin-right: auto;
        }
        
        >div {
          display: flex;
          ${SecondaryButton} {
            min-width: unset;
            margin-right: 0.75rem;
          }
        }
        
        @media (max-width: ${media.xs}px) {
          flex-wrap: wrap;
          
          ${TertiaryButton} {
            margin-bottom: 0.75rem;
            flex-grow: 1;
            width: 100%;
          }
          >div {
            width: 100%;
          }
        }
    `}
`;

StickyBlock.QuestionnaireContent.propTypes = {
    splitRowOnMobile: PropTypes.bool
};

StickyBlock.QuestionnaireContent.Actions = styled.div`
    @media (max-width: ${media.xs}px) {
        width: 100%;
    }
`;

export const SecondaryStickyBlock = styled(StickyBlock)`
  height: unset;
  background-color: white;
  margin: unset;
`;