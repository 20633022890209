import React from 'react';
import styled from 'styled-components/macro';
import {color, layout, space} from 'styled-system';
import {withTheme} from 'styled-components';
import PropTypes from 'prop-types';

const SortSvg = ({className, color, theme}) => (
    <svg viewBox='0 0 24 24' className={className} xmlns='http://www.w3.org/2000/svg'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <path d='M3,18 L9,18 L9,16 L3,16 L3,18 Z M3,6 L3,8 L21,8 L21,6 L3,6 Z M3,13 L15,13 L15,11 L3,11 L3,13 Z' fill={color || theme.colors[color]}></path>
        </g>
    </svg>
);

SortSvg.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    theme: PropTypes.object,
};

const Sort = styled(withTheme(SortSvg))`
  ${color};
  ${layout};
  ${space};
`;

Sort.defaultProps = {
    color: '#2E5299',
    height: 24,
    width: 24,
};

export default Sort;