import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, compose, createStore} from 'redux';
import env from '@beam-australia/react-env';

export const createReduxStore = (rootReducer, rootSagas) => {
    const sagaMiddleware = createSagaMiddleware();

    const reduxStore = createStore(
        rootReducer,
        ('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window && env('ENV') === 'development' ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose)(applyMiddleware(sagaMiddleware))
    );

    sagaMiddleware.run(rootSagas);

    return reduxStore;
};