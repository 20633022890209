const dashboardStore = {
    savedLicenceDetails: undefined,
    licence: undefined,
    lastFetchedLicenceNumber: '',
    fetchingLicence: 'NOT_FETCHED',
    showError: false,
    unreadNotificationsCount: 0,
    supportedEntityTypesForVerification: []
};

export default dashboardStore;
