import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ComponentLoader} from '@snsw/react-component-library';
import tokenHandler from '../utilities/tokenHandler';

export const OAuthRedirect = ({history, location, redirectTo = '/', postLoginHandler}) => {
    useEffect(() => {
            tokenHandler.getToken(location).then((data) => {
                postLoginHandler && postLoginHandler();
                const savedPath = tokenHandler.extractSavedPath(location);
                history.replace(savedPath || redirectTo);
            }).catch(() => {
                history.replace('/');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <ComponentLoader fullPage/>;
};

OAuthRedirect.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    redirectTo: PropTypes.string,
    postLoginHandler: PropTypes.func
};

export default OAuthRedirect;
