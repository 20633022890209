import React from 'react';
import PropTypes from 'prop-types';
import {PageFullWidthSection} from './PageLayout.styled';

const FullWidthLayout = ({children, backgroundColor}) =>
    <PageFullWidthSection backgroundColor={backgroundColor}>
        {children}
    </PageFullWidthSection>;

FullWidthLayout.propTypes = {
    children: PropTypes.node.isRequired,
    backgroundColor: PropTypes.string,
};

FullWidthLayout.defaultProps = {
    backgroundColor: '#f3f5f8',
};

export default FullWidthLayout;
