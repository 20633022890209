import React from 'react';
import ContentLayout from '../../ContentLayout';
import {SideBarTopLayoutContainer} from './SideBarTopLayout.styled';

const SideBarTopLayout = ({children, ...other}) => {
    return (
        <ContentLayout {...other}>
            <SideBarTopLayoutContainer>
                {children}
            </SideBarTopLayoutContainer>
        </ContentLayout>
    );
};

SideBarTopLayout.propTypes = {
    ...ContentLayout.propTypes,
};

SideBarTopLayout.defaultProps = {
    ...ContentLayout.defaultProps,
};

export default SideBarTopLayout;
