import styled from 'styled-components/macro';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import PageBreadcrumb from '../components/PageBreadcrumb';
import Content from '../components/Content';
import {EtdbButton as Button, InfoCardContainer, media, th} from '@snsw/react-component-library';
import {StickyBlock} from '../../../Blocks/StickyBlock.styled';
import MobileActionHeader from '../components/MobileActionHeader';
import PreFooter from '../components/PreFooter';
import Footer from '../components/Footer';
import {StickyBottomContainer} from '../../../Sticky/Sticky.styled';

export const GelLandingPageLayoutContainer = styled.main`
  background-color: ${props => props.theme.colors.white};
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc((100vw - 76.5rem)/2) calc(76.5rem * ${props => props.invert ? 0.3 : 0.7}) calc(76.5rem * ${props => props.invert ? 0.7 : 0.3}) calc((100vw - 76.5rem)/2);
  grid-template-columns: auto calc(76.5rem * ${props => props.invert ? 0.3 : 0.7}) calc(76.5rem * ${props => props.invert ? 0.7 : 0.3}) auto;
  grid-template-areas:
    "l-grey   breadcrumb  breadcrumb  r-grey"
    "l-grey   header      header      r-grey"
    ".        ${props => props.leftSectionName}     ${props => props.rightSectionName}     ."
    ".        pre-footer  ${props => props.rightSectionName}     ."
    "footer   footer      footer      footer";

  @media (max-width: ${media.lg}px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "breadcrumb"
      "header"
      "mobile-action-header"
      " ${props => props.leftSectionName}"
      " ${props => props.rightSectionName}"
      "pre-footer"
      "footer";
  }

  ::before, ::after {
    content: '';
    background-color: ${props => props.theme.colors.greyBackground};
  }

  ::before {
    grid-area: l-grey;
    //for ie11 only
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
      height: auto;
      -ms-grid-row-span: 2;
      display: block;
      background-color: ${props => props.theme.colors.greyBackground};
    }
  }

  ::after {
    grid-area: r-grey;
  }

  ${PageBreadcrumb}, ${Header} {
    background-color: ${props => props.theme.colors.greyBackground};

    padding-top: ${th[2.5]};
    padding-left: 1.875rem;
    padding-right: 1.875rem;

    @media (max-width: ${media.lg}px) {
      padding-top: ${th[1.5]};
    }

    @media (max-width: ${media.md}px) {
      padding-top: ${th[1]};
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  ${PageBreadcrumb} {
    grid-area: breadcrumb;

    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
  }

  ${Header} {
    grid-area: header;
    padding-bottom: ${th[2.5]};

    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
    -ms-grid-row: 2;

    :not(:first-child) {
      padding-top: ${th[1.5]};
    }

    @media (max-width: ${media.sm}px) {
      padding-bottom: ${th[2]};
    }

    > * {
      width: 70%;
      @media (max-width: ${media.lg}px) {
        width: 100%;
      }
    }

    h1 + p, h2 + p, h3 + p, h4 + p, h5 + p {
      margin-top: ${th[1.5]};

      @media (max-width: ${media.xs}px) {
        margin-top: ${th[1]};
      }
    }
  }

  ${MobileActionHeader}{
    grid-area: mobile-action-header;

    display: none;
    background-color: ${props => props.theme.colors.greyBackground};
    margin-top: -${th[2.5]};
    @media (max-width: ${media.sm}px) {
      margin-top: -${th[2]};
    }
    padding: ${th[1.5]};

    @media (max-width: ${media.lg}px) {
      display: unset;
    }
  }

  ${Content} {
    grid-area: content;

    -ms-grid-column: 2;
    -ms-grid-row: 3;

    padding-left: 1.875rem;
    padding-right: ${th[2]};

    @media (max-width: ${media.lg}px) {
      padding-right: 1.875rem;
    }
  }

  ${SideBar} {
    grid-area: side-bar;
    padding: ${props => props.invert ? '0 0 0 36px' : '0 36px 0 0'};
    // Changed to 36px th[2] to match pre-footer

    -ms-grid-column: 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;

    @media (max-width: ${media.lg}px) {
      padding-left: 1.875rem
    }

    ${InfoCardContainer} + ${InfoCardContainer}, ${StickyBottomContainer} + ${InfoCardContainer} {
      margin-top: ${th[1.5]};
    }
  }

  ${PreFooter} {
    grid-area: pre-footer;
    padding-left: 1.875rem;
    padding-right: ${th[2]};

    -ms-grid-column: 2;
    -ms-grid-row: 4;

    @media (max-width: ${media.sm}px) {
      padding-left: 1.25rem;
      padding-right: ${th[1.5]};
    }
  }

  ${Footer} {
    grid-area: footer;

    flex-direction: column;
    display: flex;
    margin-bottom: 0;

    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 5;

    @media (max-width: ${media.sm}px) {
      ${StickyBlock} {
        height: unset;
        min-height: 7rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      ${StickyBlock.SectionBlockContent} {
        flex-direction: column;
      }

      ${Button} {
        width: 100%;
      }
    }
  }

  ${Content}, ${SideBar} {
    margin-top: ${th[3]};
    margin-bottom: ${th[2.5]};

    @media (max-width: ${media.lg}px) {
      margin-bottom: ${th[1.5]};
    }

    @media (max-width: ${media.md}px) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }
`;
