import React, {useEffect, useState} from 'react';
import {FeedbackForm, FeedbackQuestion, StyledFeedbackContainer} from './Feedback.styled';
import {Button, FormGroup, StyledH3, InPageAlert, Spacer, Textarea} from 'common';
import thumbsUp from '../../images/thumbs-up.svg';
import thumbsUpSelected from '../../images/thumbs-up-selected.svg';
import thumbsDown from '../../images/thumbs-down.svg';
import thumbsDownSelected from '../../images/thumbs-down-selected.svg';
import {feedbackConfig} from './config';
import {useLocation} from 'react-router';
import {MaxWidthContainer} from '../HeaderFooter.styled';
import env from '@beam-australia/react-env';

const ERROR_MESSAGE = 'An error occurred when submitting your feedback. Please try again later.';

const Feedback = () => {
    const [enableFeedback, setEnableFeedback] = useState(false);
    const [sourceApplication,setSourceApplication] = useState('Business Profile');
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [thumbsUpClicked, setThumbsUpClicked] = useState(true);
    const [active, setActive] = useState(undefined);
    const [hasError, setHasError] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [apiErrorMessage, setApiErrorMessage] = useState(undefined);
    const location = useLocation();

    const toggleFeedback = (e,isThumbsUp) => {
        e.preventDefault();
        setHasError(false);
        setShowFeedbackForm(!showFeedbackForm);
        setThumbsUpClicked(isThumbsUp);

        setActive(!showFeedbackForm && (isThumbsUp? 'thumbs-up':'thumbs-down'));
        setApiErrorMessage(undefined);
        setSuccessMessage(undefined);
    };

    const submitFeedback = async () => {
        if(!feedback && !thumbsUpClicked) {
            setHasError(true);
            return false;
        }

        setHasError(false);

        const headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        const urlencoded = new URLSearchParams();
        urlencoded.append('rating', thumbsUpClicked?'5':'1');
        urlencoded.append('comments', feedback);
        urlencoded.append('applicationName', window.location.hostname);
        urlencoded.append('referralUrl', window.location.origin);
        urlencoded.append('userAgent', navigator.userAgent);
        urlencoded.append('sourceApplication', sourceApplication);

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: urlencoded
        };
        const submitFeedbackURL = env('ONEGOV_FEEDBACK_API');
        await fetch(submitFeedbackURL, requestOptions)
            .then((response) => {
                if(response.status === 200){
                    setSuccessMessage('Thank you for taking time to write to us. Your feedback is important to us, ' +
                        'and helps us continuously improve our services.');
                } else if(response.status >= 400){
                    setApiErrorMessage(ERROR_MESSAGE);
                }
            })
            .catch(()=> setApiErrorMessage(ERROR_MESSAGE));
    };

    useEffect(() => {
        const config = feedbackConfig.find( config => !!config.routes.find( route => route === location.pathname));
        if(config){
            setEnableFeedback(true);
            config.sourceApplication &&
            setSourceApplication(env('NODE_ENV')==='production' ? config.sourceApplication:'Business Profile');
        }
    },[location]);

    return (enableFeedback?
            <StyledFeedbackContainer>
                <MaxWidthContainer>
                    {successMessage &&
                    <InPageAlert variant='success' title={''}>
                        <p>{successMessage}</p>
                    </InPageAlert>
                    }
                    {apiErrorMessage &&
                    <InPageAlert variant='error' title={''}>
                        <p>{apiErrorMessage}</p>
                    </InPageAlert>
                    }
                    <FeedbackQuestion>
                        <StyledH3>How is your experience in the Business Profile?</StyledH3>
                        <Button variant='link' data-testid={'feedback-thumbsup'} aria-pressed={active==='thumbs-up'} onClick={(e) => toggleFeedback(e,true) }><img src={active==='thumbs-up'?thumbsUpSelected:thumbsUp} alt={'Thumbs Up'}/></Button>
                        <Button variant='link' data-testid={'feedback-thumbsdown'} aria-pressed={active==='thumbs-down'} onClick={(e) => toggleFeedback(e,false) }><img src={active==='thumbs-down'?thumbsDownSelected:thumbsDown} alt={'Thumbs Down'} /></Button>
                    </FeedbackQuestion>
                    {showFeedbackForm &&
                        <FeedbackForm>
                            <FormGroup id={'feedback'} label={`How can we make it better?${thumbsUpClicked?' (optional)':''}`} hasError={hasError}
                                             errorMessage='Please provide your feedback'>
                                <Textarea rows='small' onChange={(e)=> {
                                    !e.target.value && !thumbsUpClicked? setHasError(true): setHasError(false);
                                    setFeedback(e.target.value);
                                }}/>
                            </FormGroup>
                            <Spacer md={2}/>
                            <Button variant='secondary' onClick={() => submitFeedback()}>Submit</Button>
                        </FeedbackForm>
                    }
                </MaxWidthContainer>
            </StyledFeedbackContainer>:<></>
    );
};

export default Feedback;
