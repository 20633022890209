import env from '@beam-australia/react-env';
import api from '../utilities/api';

export default {
    redirectToLoginPage: function (savedUrl) {
        const apiCtxPath = env('API_CONTEXT_PATH');
        let loginRedirectUrl = `${window.location.origin}${apiCtxPath}/auth/login${getQuery(savedUrl)}`;

        if(apiCtxPath.startsWith('https://')){
            loginRedirectUrl = `${apiCtxPath}/auth/login${getQuery(savedUrl)}`;
        }

        redirect(loginRedirectUrl);
    },

    redirectToMFAPage: function (savedUrl) {
        const apiCtxPath = env('API_CONTEXT_PATH');
        let loginRedirectUrl = `${window.location.origin}${apiCtxPath}/auth/login-mfa${getQuery(savedUrl)}`;

        if(apiCtxPath.startsWith('https://')){
            loginRedirectUrl = `${apiCtxPath}/auth/login-mfa${getQuery(savedUrl)}`;
        }

        redirect(loginRedirectUrl);
    },

    redirectToRegistrationPage: async function redirectToRegistrationPage(savedUrl) {
        const registrationUrl = env('SSO_CREATE_ACCOUNT_URL');
        const loginUrl = await api.getSecure(`/auth/loginUrl${getQuery(savedUrl)}`);
        redirect(`${registrationUrl}?registerRedirectUrl=${encodeURIComponent(loginUrl)}`);
    },
};

const getQuery = savedPath => {
    if (savedPath) {
        const ctxPath = env('CONTEXT_PATH');
        return '?' + new URLSearchParams({
            savedPath: ctxPath && !savedPath.startsWith(ctxPath) ? ctxPath + savedPath : savedPath
        }).toString();
    }
    return '';
};

const redirect = url => window.open(url, '_self');
