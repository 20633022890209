import styled from 'styled-components/macro';
import {media} from 'common';

export const BrandingLogo = styled.div`
  @media (max-width: ${1024 - 1}px) {
    img {
      height: 32px;
    }
  }
`;

export const FullWidthStatusBar = styled.div`
  width: 100%;
  background: rgb(36, 41, 52);
`;

export const FullWidthLogoBar = styled.div`
  width: 100%;
  box-shadow: inset 0px -1px 0px 0px rgb(236, 241, 243), 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
`;

export const StatusBarContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  * {
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
  }

  @media (max-width: ${1024 - 1}px) {
    display: none;
  }
`;

export const LogoBarContainer = styled.div`
  width: 100%;
  height: 78px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  * {
    color: black;
    font-size: 1rem;
    font-weight: 300;
    text-decoration: none;
  }

  @media (max-width: ${1024 - 1}px) {
    height: 64px;
    * {
      font-size: 10px;
    }
  }
`;

export const RightSection = styled.div`
  display: flex;
  > * {
     margin-left: 32px;
  }
  
  @media (max-width: ${media.md - 1}px) {
    width: 4rem;
      > * {
          margin-left: 0px;
      }
  }
`;

export const MenuPlaceholderContainer = styled.div`
  width: 40px;
  display: none;

  @media (max-width: ${1024 - 1}px) {
    display: flex;
  }
`;

export const PickerPlaceholderContainer = styled.div`
  @media (max-width: ${1024 - 1}px) {
    display: none;
  }
`;

export const MaxWidthContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: inherit;
  max-width: 1224px;
  padding: 0 32px;
  @media (max-width: ${1024 -1}px) {
    padding: 0 20px;
  }
  @media (min-height: ${1024}px) {
    flex-grow: 1;
  }
`;

export const FooterBarContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding-top: 9px;
  padding-bottom: 9px;

  @media (max-width: ${829}px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  ul {
    list-style: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    @media (min-width: ${830}px) {
      display: flex;
      flex-wrap: wrap;
      max-width: 1045px;
      align-items: center;
      justify-content: space-between;
      height: 4.125rem;
    }

    @media (max-width: ${829}px) {
      display: block;
    }

    li {
      margin-right: 2rem;
      margin-bottom: 0;
      padding: 0;
      background: 0;
      line-height: 1.313rem;

      @media (max-width: ${829}px) {
        margin: 0 0 1rem;
        line-height: 1.5rem;
      }
    }

    li:last-child {
      margin: 0
    }
  }

  a {
    color: white;
    font-size: 0.875rem;
    font-weight: 300;
    text-decoration: none;
    background-color: transparent;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const FooterExternalLink = styled.a`
  background-image: url("data:image/svg+xml;utf8, 
      <svg height='12' width='12' fill='white' xmlns='http://www.w3.org/2000/svg'>
          <path d='M0 0v12h12V0H0zm10.9 10.9H1.1V1.1h9.8v9.8z'/>
          <path d='M4.4 2.7v1.1h3L2.3 8.9l.8.8 5.1-5.1v3h1.1V2.7H4.4z'/>
      </svg>");
  background-position: 100% 50%;
  background-size: 12px;
  background-repeat: no-repeat;
  padding-right: 16px;
`;