import React from 'react';
import styled from 'styled-components/macro';
import {color, layout, space} from 'styled-system';
import {withTheme} from 'styled-components';

// eslint-disable-next-line react/prop-types
const TickSvg = ({className, color, theme}) => (
    <svg className={className} viewBox='0 0 12 9' xmlns='http://www.w3.org/2000/svg'>
        <path fill='none' fillRule='evenodd'
              stroke={theme.colors[color] || color}
              strokeLinecap='round' strokeLinejoin='round'
              strokeWidth='2' d='M1 4.978L4.115 8 11 1'/>
    </svg>
);

const Tick = styled(withTheme(TickSvg))`
  ${color}
  ${layout}
  ${space}
`;

Tick.defaultProps = {
    color: 'darkBlue',
    height: '9px',
    width: '12px',
};

export default Tick;