import styled from 'styled-components/macro';
import SideBar from '../components/SideBar';
import {InfoCardContainer, media, th} from '@snsw/react-component-library';
import BaseLayoutContainer from '../BaseLayoutContainer.styled';

const BaseSideBarLayoutContainer = styled(BaseLayoutContainer)`    
  grid-template-areas: 
    "breadcrumb        breadcrumb"
    "header            header"
    "content-header    ."
    "content           side-bar"
    "pre-footer        ."
    "footer            footer"
    "secondary-footer  secondary-footer";
  
  grid-column-gap: 2rem;

  ${SideBar} {
    grid-area: side-bar;

    > ${InfoCardContainer} + ${InfoCardContainer} {
      margin-top: ${th[1.5]};
    }
  }  
`;

export const SideBarTopLayoutContainer = styled(BaseSideBarLayoutContainer)`  
  grid-template-columns: minmax(calc(70% - 2rem), 7fr) minmax(30%, 3fr);
  
  @media (max-width: ${media.lg}px) {
    grid-template-columns: minmax(100%, 10fr);
    grid-template-areas: 
      "breadcrumb"
      "header"
      "action-header"
      "content-header"
      "side-bar"
      "content"
      "pre-footer"
      "footer"
      "secondary-footer";
  }
`;
